import React, { Component } from 'react'
import Axios from 'axios'

import { StateContext } from '../../context/AppContext';
import { FaLanguage } from 'react-icons/fa';

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import JobsList from '../../components/JobsList';
import CurrentBalance from '../../components/CurrentBalance';
import DragAndDrop from '../../components/DragAndDrop';

import InputGroup from 'react-bootstrap/InputGroup';
import FormLabel from 'react-bootstrap/FormLabel';
import FormCheck from 'react-bootstrap/FormCheck';
import Form from 'react-bootstrap/Form';
import FormGroup from 'react-bootstrap/FormGroup';
import ReactGA from "react-ga4";

import { toast } from 'react-toastify';

class Jobs extends Component {
    constructor(props) {
        super(props);
        this.child = React.createRef();
    }

    static contextType = StateContext;

    jobLanguages = [
        { code: "en-US", name: "English (United States)" },
        { code: "en-GB", name: "English (Great Britain)" },
        { code: "bg-BG", name: "Bulgarian" },
        { code: "hr-HR", name: "Croatian" },
        { code: "cs-CZ", name: "Czech" },
        { code: "da-dk", name: "Danish" },
        { code: "nl-NL", name: "Dutch" },
        { code: "fi-FI", name: "Finnish" },
        { code: "fr-FR", name: "French" },
        { code: "de-DE", name: "German" },
        { code: "el-GR", name: "Greek" },
        { code: "hu-HU", name: "Hungarian" },
        { code: "it-IT", name: "Italian" },
        { code: "nb-NO", name: "Norwegian" },
        { code: "pl-PL", name: "Polish" },
        { code: "pt-PT", name: "Portugese" },
        { code: "ro-RO", name: "Romanian" },
        { code: "sk-SK", name: "Slovak" },
        { code: "sl-SI", name: "Slovenian" },
        { code: "es-US", name: "Spanish (United States)" },
        { code: "es-ES", name: "Spanish (Spain)" },
        { code: "sv-SE", name: "Swedish" },
        { code: "tr-TR", name: "Turkish" }
    ]

    state = {
        FileNames: [],
        files: [],
        ConfirmJobs: false,
        lastjob: "",
        lastjobname: "",
        LastServerMessage: "",
        BillingType: 0,
        joblanguage: "en-US",
        detectlanguage: false
    }

    componentWillMount() {

        const [{ IsLoggedIn, _billingtype, _authtoken }, dispatch] = this.context;

        if (!IsLoggedIn || !_authtoken) {
            this.setState({ IsLoggedIn: false });
            this.props.history.push('/login?redirecturi=jobs');
        }
        else {
            this.setState({ BillingType: _billingtype });
            this.setState({ IsLoggedIn: true });
        }
    }

    componentDidMount() {

        const [{ IsLoggedIn }, dispatch] = this.context;

        Axios.get("/account", {
        }).then(response => {
            this.setState({
                "joblanguage": response.data.defaultjoblanguage,
                "detectlanguage": response.data.detectlanguage
            });

            dispatch({
                parameter: '_DefaultJobLanguage',
                newValue: response.data.defaultjoblanguage
            });

            dispatch({
                parameter: '_DetectLanguage',
                newValue: response.data.detectlanguage
            });

        }).catch((error) => {
            if (error.message === "Operation canceled due to invald token.") this.props.history.push('/login');
            this.setState(this.state);
        });
    }

    componentDidUpdate() {

        if (this.props.servermessage && this.props.servermessage !== this.state.LastServerMessage) {
            this.setState({
                LastServerMessage: this.props.servermessage
            });
        }
    }

    errormessages = {
    }

    UpdateState = (e) => this.setState({ [e.target.name]: e.target.value.trim() });
    UpdateCheckboxState = (e) => this.setState({ [e.target.name]: e.target.checked });

    Validate() {

        var bResult = true;



        this.setState(this.state);
        return bResult;
    };

    async CancelJobs() {
        this.setState({ ConfirmJobs: false });
    };

    handleSelect = (event) => {

        if (!event || !event.target || !event.target.files || event.target.files.length === 0) return;

        let FileNames = [];

        for (let i = 0; i < event.target.files.length; i++) {
            FileNames.push(event.target.files[i].name);
        }

        this.setState({
            FileNames: FileNames,
            files: event.target.files,
            ConfirmJobs: true
        });
    };

    handleDrop = (files) => {

        if (!files || files.length === 0) { return };

        let FileNames = [];

        for (var i = 0; i < files.length; i++) {
            if (files[i].name) {
                FileNames.push(files[i].name);
            }
        }

        this.setState({
            FileNames: FileNames,
            files: files,
            ConfirmJobs: true
        });
    };

    ConfirmJobs = () => {

        this.setState({ ConfirmJobs: false });

        let files = this.state.files;
        for (var i = 0; i < files.length; i++) {
            if (!files[i].name) return;

            // For each of the files dropped in, create a transcription job request

            const oData = {
                "requesturl": files[i].name,
                "name": files[i].name,
                "language": this.state.joblanguage,
                "detectlanguage": this.state.detectlanguage
            };

            var options = {
                headers: {
                    'Content-Type': "application/octet-stream"
                }
            };

            let TargetFile = files[i];

            var reader = new FileReader();
            reader.readAsDataURL(files[i]);

            let FileName = files[i].name;

            toast("Uploading file", {
                bodyClassName: "toast-message",
            });

            Axios.post("/transcribe", JSON.stringify(oData),
                {}).then(response => {
                    FileName = response.data.jobid + "/" + FileName;
                    this.setState({
                        lastjob: response.data.jobid,
                        lastjobname: response.data.jobname
                    }, function () {
                        fetch(response.data.url, {
                            method: "PUT",
                            headers: {
                                'Content-Type': TargetFile.type
                            },
                            body: TargetFile
                        }).then(responseB => {

                            // Submit a GA event

                            ReactGA.event({
                                category: "Jobs",
                                action: "Job-Submitted"
                            });
                        }
                        ).catch(
                            error => {
                            }
                        );
                    });
                }).catch(error => {

                    if (typeof error.response === "undefined") { this.errormessages.submission = "Error"; }
                    else {
                        this.errormessages.submission = error.response.data.errormessage;
                    }
                    this.setState(this.state);

                });
        }
    };

    render() {

        //const [{ _DefaultJobLanguage }] = this.context;

        return (
            <>
                <Modal show={this.state.ConfirmJobs} onHide={this.CancelJobs.bind(this)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Confirm Transcription Request</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        Please confirm that you wish to process the following files:
                        <br /><br />
                        {this.state.FileNames.map((file) => {
                            return (
                                <span key={file}>{file}<br /></span>
                            );
                        })}<br />
                        <FormGroup controlId="JobLanguageGroup">
                            <FormLabel>in this language:</FormLabel>
                            <InputGroup>
                                <InputGroup.Prepend>
                                    <InputGroup.Text id="inputGroupPrepend"><FaLanguage className="companycolour" /></InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control name="joblanguage" as="select" disabled={this.state.detectlanguage} onChange={this.UpdateState} value={this.state.joblanguage}>
                                    {this.jobLanguages.map((language) => {
                                        return (
                                            <option key={language.code} value={language.code}>{language.name}</option>
                                        );
                                    })}
                                </Form.Control>
                            </InputGroup>
                            <InputGroup>
                                <br />
                                <FormCheck name="detectlanguage" checked={this.state.detectlanguage} onChange={this.UpdateCheckboxState} type="checkbox" label="Check this box if you would like 3Scribe to automatically attempt to detect the language spoken in your media file." />
                            </InputGroup>
                        </FormGroup>

                        <br /><br />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.CancelJobs.bind(this)}>
                            Cancel
                        </Button>
                        <Button className="ConfirmButton" onClick={this.ConfirmJobs.bind(this)}>
                            Confirm
                        </Button>
                    </Modal.Footer>
                </Modal>
                <div className="container-fluid">
                    <DragAndDrop handleDrop={this.handleDrop}>

                        <div className="row justify-content-center">
                            <div className="col-md-10 col-lg-10 justify-content-center">
                                <div className="smallfillerpanel" />
                                <div className="row justify-content-left">
                                    {this.state.BillingType === 0 && <CurrentBalance />}
                                    <div className="col"></div>
                                    <div className="col-md-5 border currentbalance">
                                        <div className="row header">
                                            <div className="col">Upload A File</div>
                                        </div>
                                        <div className="row text">
                                            <div className="col">
                                                Select a file below or drag and drop multiple files on to this page to upload and begin transcription.<br /><br />
                                                <div className="custom-file">
                                                    <input type="file" className="custom-file-input" id="customFile" multiple onChange={this.handleSelect} />
                                                    <label className="custom-file-label" htmlFor="customFile">Choose file</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="smallfillerpanel" />
                            </div>
                        </div>

                        <JobsList lastjob={this.state.lastjob} servermessage={this.state.LastServerMessage} defaultlanguage={this.state.joblanguage} />
                    </DragAndDrop>
                </div>
            </>
        );
    }
}

export default Jobs;