import React, { createContext, useContext, useReducer } from 'react';
import Axios from 'axios';
const jwt_decode = require('jwt-decode');

// Set default values for the globals

const defaultValues = {

    // Static keys

    auth0_clientid: 'm1e58wGoQJaSI60Qh4e2u2wf6U0EseGO',
    auth0_domain: 'security.3scri.be',

    stripe_key: 'pk_live_OYRI3vtnvzqI167HVZIGFWEH00O2H2JTSi',

    //stripe_key: 'pk_test_uwhImFSyeJ8WXKXO8sJYn9b300bixBKJau',

    recaptcha_key: "6LeAJLYZAAAAAHaPLw9pibZIAvy_0n2-rgaPAthu",

    // Global values

    _authtoken: (localStorage.getItem('_authtoken') === null ? '' : localStorage.getItem('_authtoken')),
    IsLoggedIn: false,
    _username: (localStorage.getItem('_username') === null ? '' : localStorage.getItem('_username')),
    _gravitar: (localStorage.getItem('_gravitar') === null ? '' : localStorage.getItem('_gravitar')),

    _JobsList: (localStorage.getItem('_JobsList') === null ?
        {
            PerPage: 10,
            Order: 1,
            Direction: 1
        } :
        JSON.parse(localStorage.getItem('_JobsList'))),

    _SiteLanguage: (localStorage.getItem('_SiteLanguage') === null ? 'en' : localStorage.getItem('_SiteLanguage')),
    _DefaultJobLanguage: (localStorage.getItem('_DefaultJobLanguage') === null ? 'en-US' : localStorage.getItem('_DefaultJobLanguage')),

    InDevelopment: true
};

// Check to see if the stored JWT has expired

if (defaultValues._authtoken) {
    if (jwt_decode(defaultValues._authtoken).exp > (Date.now().valueOf() / 1000)) {
        defaultValues.IsLoggedIn = true;
    }
}

// Set up the global defaults for the Axios object

Axios.defaults.baseURL = 'https://dashboard.3scri.be';
Axios.defaults.headers.post['Content-Type'] = "application/json";

if (defaultValues.IsLoggedIn) {
    Axios.defaults.headers.common['Authorization'] = "Bearer " + defaultValues._authtoken;
}

// Add a request interceptor

Axios.interceptors.request.use((config) => {

    if (config.headers.common.Authorization) {
        if (jwt_decode(config.headers.common.Authorization).exp < (Date.now().valueOf() / 1000)) {
            throw new Axios.Cancel('Operation canceled due to invald token.');
        }
    }

    return config;

}, (err) => {
    return Promise.reject(err);
});


// Set up the default reducer to update the local storage and the global state

const defaultreducer = (state, action) => {

    // If the parameter name is prefixed with an underscore then save it to the local storage so it persists

    if (action.parameter.substring(0, 1) === "_") {
        if (Object.prototype.toString.call(action.newValue) === "[object Object]") {
            localStorage.setItem(action.parameter, JSON.stringify(action.newValue));
        }
        else {
            localStorage.setItem(action.parameter, action.newValue);
        }
    }

    return {
        ...state,
        [action.parameter]: action.newValue
    };
};

// Export the context

export const StateContext = createContext();
export const StateProvider = ({ reducer, initialState, children }) => (
    <StateContext.Provider value={useReducer(defaultreducer, defaultValues)}>
        {children}
    </StateContext.Provider>
);
export const useStateValue = () => useContext(StateContext);