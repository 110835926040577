import React, { Component } from 'react';
import Axios from 'axios';

import { StateContext } from '../../context/AppContext';
import { withRouter, Link } from 'react-router-dom'

import Alert from 'react-bootstrap/Alert';

import { FaLock, FaAt } from 'react-icons/fa';

import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import FormGroup from 'react-bootstrap/FormGroup';
import InputGroup from 'react-bootstrap/InputGroup';
import FormLabel from 'react-bootstrap/FormLabel';
import FormText from 'react-bootstrap/FormText';
import FormCheck from 'react-bootstrap/FormCheck';
import Button from 'react-bootstrap/Button';

import queryString from 'query-string'
import ReactGA from "react-ga4";


class UnverifiedEmail extends Component {

    static contextType = StateContext;

    state = {
        email: '',
        validated: false,
        accepted: false
    };

    UpdateState = (e) => this.setState({ [e.target.name]: e.target.value.trim() });

    componentDidMount() {


    };

    processEmailValidation = event => {

        const form = event.currentTarget;

        if (form.checkValidity() === false) {
            this.setState({ validated: false });
            event.preventDefault();
            event.stopPropagation();
        }
        else {

            // Submit the request to create a new user.

            Axios.post("/verifyemail", JSON.stringify(this.state), {
            }).then(response => {

                if (response.status === 200) {
                    this.setState({ accepted: true });

                    // Submit a GA event

                    ReactGA.event({
                        category: "Account",
                        action: "Email-Verified"
                    });
                }

            }).catch(error => {

                if (typeof error.response.data === "undefined") { this.errormessages.submission = "Error"; }
                else {
                    this.errormessages.submission = error.response.data.errormessage;
                }

                this.setState({ validated: true });
            });
            event.preventDefault();
            event.stopPropagation();
        }
    }

    render() {
        return (
            <>
                <div className="row fillerpanel">
                </div>
                <div className="row justify-content-center">
                    <div className="loginpanel border col-md-4 col-lg-4 col-sm-4">
                        <Form className="login" validated={this.state.validated} onSubmit={this.processEmailValidation.bind(this)}>
                            <FormText className="box-header">
                                Verify your email address
                            </FormText>
                            <FormText className="box-text">
                                Before you're be able to use 3Scribe you'll have to validate your email address.&nbsp;&nbsp;When you registered you should have received an email containing a link.&nbsp;&nbsp;If you still have that email you can click the link now but if the link has expired or you no longer have the email then enter your email address below and we'll resend the validation.
                            </FormText>

                            {this.state.accepted &&
                                <Alert variant="success">
                                    Thank you.  You should receive an email shortly containing a link to verify your address.
                                </Alert>
                            }

                            <FormGroup controlId="EmailAddressGroup">
                                <FormLabel>Email address</FormLabel>
                                <InputGroup>
                                    <InputGroup.Prepend>
                                        <InputGroup.Text id="inputGroupPrepend"><FaAt className="companycolour" /></InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <FormControl name="email" type="email" placeholder="Enter email" autoFocus="autofocus" onChange={this.UpdateState} />
                                    <FormControl.Feedback type="invalid">Please enter a valid email address</FormControl.Feedback>
                                </InputGroup>
                            </FormGroup>

                            <FormGroup>
                                <Button type="submit" variant="primary">Send Verification</Button>
                            </FormGroup>
                        </Form>
                    </div>
                </div>
                <div className="row fillerpanel"></div>
                <div className="row fillerpanel"></div>
            </>
        );
    }
}

export default withRouter(UnverifiedEmail);