import Axios from 'axios';
import React, { Component } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Navbar from 'react-bootstrap/Navbar';
import { withRouter } from 'react-router-dom';
import Panels from '../../components/Panels';
import { StateContext } from '../../context/AppContext';
import Login from '../../pages/account/Login';
import ReactGA from "react-ga4";

class OAuthRedirect extends Component {
    constructor(props) {
        super(props);
    }

    static contextType = StateContext;

    state = {
        panel: "OAuth/waiting/" + this.props.service
    }

    UpdateState = (e) => this.setState({ [e.target.name]: e.target.value.trim() });

    componentDidMount() {

        document.querySelector('#closeButton').style.visibility = 'hidden';

        Axios.post("https://integrations.3scri.be/authorize", {
            service: this.props.service,
            querystring: this.props.location.search
        }).then(response => {

            // Submit a GA event

            ReactGA.event({
                category: "Integrations",
                action: "Registered",
                label: this.props.service
            });

            // Redirect/react as appropiate or close the window  

            var setNoClose = false;

            console.log(response.data);

            response.data.actions.map(sAction => {
                var sVerb = (sAction.indexOf(":") > -1 ? sAction.substring(0, sAction.indexOf(":")) : sAction);
                var sNoun = (sAction.indexOf(":") > -1 ? sAction.substring(sAction.indexOf(":") + 1) : "");

                switch (sVerb) {
                    case "redirect":
                        window.location.replace(sNoun);
                        break;

                    case "panel":
                        this.setState({ panel: sNoun });
                        break;

                    case "close":
                        window.open("", "_self");
                        window.close();
                        break;

                    case "noclose":
                        setNoClose = true;
                        break;

                    default:
                        break;
                };
            });

            if (!setNoClose) {
                //document.querySelector('#closeButton').style.visibility = 'visible';
            }

        }).catch((error) => {
            if (error.message === "Operation canceled due to invald token.") this.props.history.push('/login');

            this.props.history.push("/integrations/error/" + this.props.service);
        });
    };

    closeAuthorization = event => {

        window.open("", "_self");
        window.close();

        event.preventDefault();
        event.stopPropagation();
    };

    render() {

        const [{ IsLoggedIn }] = this.context;

        return (
            <>
                <Navbar collapseOnSelect expand="lg" variant="3scribe" className="nav-02 navbar-light">
                    <Navbar.Brand>
                        <img
                            alt="logo"
                            src={window.location.protocol + '//' + window.location.host + '/logo.svg'}
                            width="50"
                            height="50"
                            className="d-inline-block align-center"
                        />
                        &nbsp;3Scribe
                    </Navbar.Brand>
                </Navbar>

                {(
                    (!IsLoggedIn && <Login redirectoverride={"integrations/redirect/" + this.props.service} querystringoverride={this.props.location.search} />) ||
                    (IsLoggedIn &&
                        <Form className="authorize" onSubmit={this.closeAuthorization}>
                            <Panels pagetitle="OAuth Authentication" panelslist={[this.state.panel]} />
                            <div className="row justify-content-center">
                                <Button type="submit" variant="primary" id="closeButton">CLOSE</Button>
                            </div>
                        </Form>
                    )
                )}
            </>
        );
    }
}

export default withRouter(OAuthRedirect);