import ReactGA from 'react-ga4';
import React, { Component } from 'react';
import { Elements, StripeProvider } from 'react-stripe-elements';


import { ToastContainer, toast } from 'react-toastify';
import { Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Navbar from 'react-bootstrap/Navbar';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';

import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';


import { BrowserRouter as Router, Route, Link, Switch } from 'react-router-dom';
import history from "./history";
import { StateContext } from './context/AppContext';

import Register from './pages/account/Register';
import Login from './pages/account/Login';
import Logout from './pages/account/Logout';
import UnverifiedEmail from './pages/account/UnverifiedEmail';
import PasswordReset from './pages/account/PasswordReset';
import ForgottenPassword from './pages/account/ForgottenPassword';

import ModifyAccount from './pages/account/Account';
import Payments from './pages/account/Payments';
import APIKey from './pages/account/APIKey';
import Settings from './pages/account/Settings';

import Integrations from './pages/integrations/Integrations';
import WebhookSubscribe from './pages/integrations/WebhookSubscribe';

import Jobs from './pages/jobs/Jobs';
import JobDetails from './pages/jobs/JobDetails';

import Navigation from './components/Navigation';
import Footer from './components/Footer';
import Panels from './components/Panels';

import Policies from './components/Policies';

import KnowledgeBase from './components/KnowledgeBase';
import SupportTicket from './components/SupportTicket';


import Markdown from './components/Markdown';
import Axios from 'axios';

import OAuthRegistration from './components/integrations/OAuthRegistration';
import OAuthRedirect from './components/integrations/OAuthRedirect';

import ZapierEmbed from './components/integrations/ZapierEmbed';

import './App.css';
import './Dashboard.css';

history.listen(location => {
    ReactGA.set({ page: location.pathname })
    ReactGA.pageview(location.pathname)
})

class App extends Component {

    static contextType = StateContext;

    state = {
        language: 'en',
        articledata: []
    };

    initializeReactGA() {
        ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS);
        ReactGA.pageview('pageview');
    };

    static oSocket;

    onSocketOpen = (event) => {
        const [{ IsLoggedIn, _authtoken }, dispatch] = this.context;

        if (IsLoggedIn) {
            var msg = {
                action: 'authenticate',
                payload: { token: _authtoken }
            };

            this.oSocket.send(JSON.stringify(msg));
        }
    };

    onSocketMessage = (event) => {
        const message = JSON.parse(event.data)
        this.setState({ dataFromServer: message })
    };

    onSocketClosed = (event) => {
        const [{ IsLoggedIn, _authtoken }, dispatch] = this.context;

        if (IsLoggedIn) {
            this.oSocket = new WebSocket(process.env.REACT_APP_API_WEBSOCKETS);
            this.oSocket.addEventListener('open', this.onSocketOpen);
            this.oSocket.addEventListener('message', this.onSocketMessage);
            this.oSocket.addEventListener('close', this.onSocketClosed);
        }
    };

    componentDidMount() {

        const [{ IsLoggedIn, _authtoken }, dispatch] = this.context;

        // Load the Knowledge base articles

        const axiosInstance = Axios.create({
            baseURL: window.location.protocol + "//" + window.location.hostname + ":" + window.location.port
        });

        axiosInstance.get("articles/" + this.state.language + "/KnowledgeBase.json", {
            headers: {
                'Content-Type': "json"
            }
        }).then(response => {
            this.setState({ articledata: response.data });
        }).catch((error) => {
            console.log(error);
        });

        ReactGA.pageview(window.location.pathname);

        // Connect to the websocket

        this.onSocketClosed();
    }

    closeWindow = event => {

        window.open("", "_self");
        window.close();
        event.preventDefault();
        event.stopPropagation();
    }

    render() {

        const [{ IsLoggedIn }, dispatch] = this.context;

        return (
            <Router history={history}>
                <div className="container-fluid">
                    <Switch>
                        <Route path="/integrations/register" />
                        <Route path="/integrations/redirect" />
                        <Route path="/integrations/error" />
                        <Route path="/integrations/register/:service" />
                        <Route path="/integrations/redirect/:service" />
                        <Route path="/integrations/error/:service" />
                        <Navigation />
                    </Switch>

                    <ToastContainer position="top-right"
                        autoClose={2500}
                        hideProgressBar
                        newestOnTop
                        closeOnClick
                        rtl={false}
                        pauseOnVisibilityChange
                        draggable
                        pauseOnHover
                        transition={Slide}
                    />

                    <Route exact path="/" render={(props) => (
                        <div className="App">
                            <Panels panelslist={(IsLoggedIn ? [
                                "latestnews", "upcoming"
                            ]
                                :
                                [
                                    "hero", "features", "steps", "pricing", "newsletter"
                                ])} />
                        </div>
                    )} />
                    <Route path="/register" component={Register} />
                    <Route path="/account" component={ModifyAccount} />
                    <Route path="/payments" component={Payments} />
                    <Route path="/apikey" component={APIKey} />

                    <Route path="/settings" component={Settings} />
                    <Route path="/login" component={Login} />
                    <Route path="/logout" component={Logout} />
                    <Route path="/passwordreset" component={PasswordReset} />
                    <Route path="/forgottenpassword" component={ForgottenPassword} />
                    <Route path="/unverifiedemail" component={UnverifiedEmail} />

                    <Route exact path="/jobdetails/:jobid" component={JobDetails} />
                    <Route exact path="/jobdetails" render={(props) => <Jobs {...props} servermessage={this.state.dataFromServer} />} />

                    <Route path="/jobs" render={(props) => <Jobs {...props} servermessage={this.state.dataFromServer} />} />

                    <Route path="/registerconfirm" render={(props) => <Panels {...props} panelslist={[
                        "registerconfirm"
                    ]} />} />

                    <Route path="/aboutus" render={(props) => <Panels {...props} pagetitle="About Us" panelslist={[
                        "aboutus"
                    ]} />} />

                    <Route path="/emailverified" render={(props) => <Panels {...props} panelslist={[
                        "emailconfirmed"
                    ]} />} />

                    <Route path="/privacypolicy" render={(props) => <Policies {...props} policyname="privacy-policy" policytitle="Privacy Policy" pagetitle="Privacy Policy" />} />
                    <Route path="/termsofservice" render={(props) => <Policies {...props} policyname="terms-and-conditions" policytitle="Terms and Conditions" pagetitle="Terms and Conditions" />} />

                    <Route path="/contactus" render={(props) => <Panels {...props} pagetitle="Contact Us" panelslist={[
                        "contactus"
                    ]} />} />

                    <Route path="/news" render={(props) => <Panels {...props} pagetitle="Latest News" panelslist={[
                        "latestnews", "upcoming"
                    ]} />} />

                    <Route path="/workwithus" render={(props) => <Panels {...props} pagetitle="Jobs" panelslist={[
                        "jobs"
                    ]} />} />

                    <Route path="/faq" render={(props) => <Panels {...props} panelslist={[
                        "faq"
                    ]} />} />

                    <Route path="/benchmarks" render={(props) => <Panels {...props} panelslist={[
                        "holdingpage"
                    ]} />} />

                    <Route path="/pricing" render={(props) => <Panels {...props} pagetitle="Pricing" panelslist={[
                        "pricing"
                    ]} />} />

                    <Route path="/tutorials" render={(props) => <Panels {...props} panelslist={[
                        "holdingpage"
                    ]} />} />

                    <Route path="/newsletter" render={(props) => <Panels {...props} pagetitle="Newsletter" panelslist={[
                        "newsletter"
                    ]} />} />

                    <Route path="/newsletterfinalconfirmation" render={(props) => <Panels {...props} panelslist={[
                        "newsletterfinalconfirmation"
                    ]} />} />

                    <Switch>
                        <Route path="/integrations/register/:service" render={(props) => <OAuthRegistration {...props} service={props.match.params.service} />} />
                        <Route path="/integrations/redirect/:service" render={(props) => <OAuthRedirect {...props} service={props.match.params.service} />} />
                        <Route path="/integrations/error/:service" render={(props) =>
                            <>
                                <Navbar collapseOnSelect expand="lg" variant="3scribe" className="nav-02 navbar-light">
                                    <Navbar.Brand>
                                        <img
                                            alt="logo"
                                            src={window.location.protocol + '//' + window.location.host + '/logo.svg'}
                                            width="50"
                                            height="50"
                                            className="d-inline-block align-center"
                                        />&nbsp;3Scribe
                                </Navbar.Brand>
                                </Navbar>
                                <Panels pagetitle="OAuth Error" panelslist={['OAuth/errors/' + props.match.params.service]} />
                                <div className="row justify-content-center">
                                    <Form className="authorize" onSubmit={this.closeWindow}>
                                        <Button type="submit" variant="primary">CLOSE</Button>
                                    </Form>
                                </div>
                            </>
                        } />

                        <Route path="/integrations/yourintegrations" component={Integrations} />
                        <Route path="/integrations/zapier" component={ZapierEmbed} />

                        <Route path="/integrations" render={(props) => <Panels {...props} pagetitle="Integrations" panelslist={[
                            "integrations-header-brochure", "integrations"
                        ]} />} />
                    </Switch>

                    <Route path={["/helpcenter", "/knowledgebase/:language?/:category?/:section?/:article?"]} render={(props) => (
                        <div className="HelpCenterWrapper">
                            <div className="HelpCenterInnerWrapper">
                                <Tabs defaultActiveKey="knowledgebase" id="uncontrolled-tab-example" className="TabBar">
                                    <Tab eventKey="knowledgebase" title="Knowledge Base">
                                        <KnowledgeBase {...props} />
                                    </Tab>
                                    <Tab eventKey="contactsupport" title="Contact Support">
                                        {(IsLoggedIn &&
                                            <>
                                            <Panels pagetitle="Help Center" panelslist={["helpcenter-loggedin"]} />
                                            <SupportTicket {...props} />
                                            </>
                                         )}

                                        {(!IsLoggedIn &&
                                            <Panels pagetitle="Help Center" panelslist={["helpcenter"]} />
                                        )}

                                    </Tab>
                                </Tabs>
                            </div>
                        </div>
                    )} />

                    <Switch>
                        <Route path="/integrations/register" />
                        <Route path="/integrations/redirect" />
                        <Route path="/integrations/error" />
                        <Route path="/integrations/register/:service" />
                        <Route path="/integrations/redirect/:service" />
                        <Route path="/integrations/error/:service" />
                        <Footer />
                    </Switch>
                </div>
            </Router>
        );
    }
}

export default App;