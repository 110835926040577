import React, { Component } from 'react';
import Axios from 'axios';

import { StateContext } from '../../context/AppContext';
import { withRouter } from 'react-router-dom'
import Panels from '../../components/Panels';

import queryString from 'query-string'

class WebhookSubscribe extends Component {

    static contextType = StateContext;

    UpdateState = (e) => this.setState({ [e.target.name]: e.target.value.trim() });

    state = {
        panelslist: []
    };

    zoomsubscribe = {
        ZoomCode: ""
    }

    componentWillMount() {

        const [{ IsLoggedIn, _authtoken }, dispatch] = this.context;

        // If the user was directed here as part of a pages auth check then redirect back to the page when the user has logged in.
        
        if (!IsLoggedIn || !_authtoken) {
            this.setState({ IsLoggedIn: false });
            this.props.history.push('/login' + (this.props.location.search !== "" ? this.props.location.search + "&" : "?") + "redirecturi=integrations/zoom/subscribe");
        }
        else {
            this.setState({ IsLoggedIn: true });
        }
    }


    componentDidMount() {

        const params = queryString.parse(this.props.location.search);

        // Register the user for the selected service

        switch (this.props.service) {
            case "zoom":
                // Display the Zoom subscribed message

                this.state.panelslist = ["zoomsubscribed"];

                // Update the backend to create the Zoom subscription

                this.zoomsubscribe.ZoomCode = params["code"];

                Axios.post("https://integrations.3scri.be/zoom/subscribe", JSON.stringify(this.zoomsubscribe), {
                }).then(response => {
                }).catch(error => {
                    console.log(error);
                });
                               
                break;
        }
    };

    render() {
        return (
            <>
                <div className="row fillerpanel"></div>
                <Panels {...this.props} panelslist={this.state.panelslist} />
                <div className="row fillerpanel"></div>
            </>
        );
    }
}

export default withRouter(WebhookSubscribe);