import React, { Component } from 'react'

import { StateContext } from '../../context/AppContext';
import { withRouter, Link } from 'react-router-dom';

import { FaLock, FaAt } from 'react-icons/fa';

import Alert from 'react-bootstrap/Alert';

import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import FormGroup from 'react-bootstrap/FormGroup';
import InputGroup from 'react-bootstrap/InputGroup';
import FormLabel from 'react-bootstrap/FormLabel';
import FormText from 'react-bootstrap/FormText';
import Button from 'react-bootstrap/Button';
import Loader from 'react-loader-spinner';
import { FormCheck } from 'react-bootstrap';

import Axios from 'axios'
import Recaptcha from 'react-google-invisible-recaptcha';

export default class Integrations extends Component {
    constructor(props) {
        super(props);
    }

    static contextType = StateContext;

    state = {
    }

    componentWillMount() {

        const [{ IsLoggedIn, _authtoken }, dispatch] = this.context;

        if (!IsLoggedIn || !_authtoken) {
            this.setState({ IsLoggedIn: false });
            this.props.history.push('/login?redirecturi=integrations');
        }
        else {
            this.setState({ IsLoggedIn: true });
        }
    }






    render() {

        return (
            <>
                <div className="container-fluid">
                    <div className="row justify-content-center">
                        <div className="col-md-8 col-lg-8 justify-content-center">
                            <div className="smallfillerpanel" />
                            <div className="row justify-content-left">
                                <div className="col text">
                                <Tabs defaultActiveKey="installed" id="uncontrolled-tab-example">
                                    <Tab eventKey="installed" title="Your Integrations">
                                    </Tab>
                                    <Tab eventKey="all" title="All Integrations">
                                    </Tab>
                                </Tabs>
                                </div>
                            </div>
                            <div className="smallfillerpanel" />
                        </div>
                    </div>
                </div>
            </>
        );
    }
}