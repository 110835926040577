import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import Axios from 'axios';
import { Helmet } from "react-helmet";
import { Remark } from 'react-remark';
import gfm from 'remark-gfm';


import marked from 'marked';

class Markdown extends Component {

    constructor(props) {
        super(props);

        marked.setOptions({
            gfm: true,
            tables: true,
            breaks: false,
            pedantic: false,
            sanitize: true,
            smartLists: true,
            smartypants: false
        });
    }

    state = {
        language: 'en',
        Markdown: '',
        ClassList: ''
    }

    pagetitle = "";

    componentDidMount() {

        const { match: { params } } = this.props;

        this.setState({ language: params.language });

        if (params.articlename) {
            this.pagetitle = params.article;
        }

        const axiosInstance = Axios.create({
            baseURL: window.location.protocol + "//" + window.location.hostname + ":" + window.location.port
        });

        const articleUrl = "articles/" + params.language + "/" + params.category + "/" + params.section + "/" + params.article + ".md"

        axiosInstance.get(articleUrl, {
            headers: {
                'Content-Type': "text"
            }
        }).then(response => {
            this.setState({ Markdown: response.data, ClassList: params.category.toLowerCase().split(" ").join("-") + " " + params.section.toLowerCase().split(" ").join("-") + " " + params.article.toLowerCase().split(" ").join("-") });
        }).catch((error) => {
            console.log(error);
        });
    }

//                    <ReactMarkdown remarkPlugins={[gfm]} children={this.state.Markdown}
//    transformLinkUri={uri => uri.startsWith("http") ? uri : `${window.location.protocol + "//" + window.location.hostname + ":" + window.location.port + "/articles/" + this.state.language + "/"}${uri}`}
//    transformImageUri={uri => uri.startsWith("http") ? uri : `${window.location.protocol + "//" + window.location.hostname + ":" + window.location.port + "/articles/"}${uri}`}
///>



    render() {
        return (
            <>
                {this.pagetitle !== "" &&
                    <Helmet>
                        <title>{this.pagetitle} | 3Scribe</title>
                    </Helmet>
                }
                <div className={this.state.ClassList} dangerouslySetInnerHTML={{ __html: marked(this.state.Markdown) }} />
            </>
        );
    }
}

export default withRouter(Markdown);