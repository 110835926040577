import React, { Component } from 'react';
import { StateContext } from '../context/AppContext';
import Axios from 'axios';

import { secondsToHoursMinutes } from '../globals/text';

import { FaLock, FaUser, FaCreditCard } from 'react-icons/fa';

import Modal from 'react-bootstrap/Modal';
import Alert from 'react-bootstrap/Alert';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import FormGroup from 'react-bootstrap/FormGroup';
import InputGroup from 'react-bootstrap/InputGroup';
import FormLabel from 'react-bootstrap/FormLabel';
import FormText from 'react-bootstrap/FormText';
import Button from 'react-bootstrap/Button';
import ReactGA from "react-ga4";

import {
    CardNumberElement,
    CardExpiryElement,
    CardCvcElement,
    injectStripe
} from 'react-stripe-elements';

class Checkout extends Component {

    constructor(props) {
        super(props);
        this.makePayment = this.makePayment.bind(this);
        this.ConfirmPayment = this.ConfirmPayment.bind(this);
        this.applyVoucher = this.applyVoucher.bind(this);
        this.ConfirmVoucher = this.ConfirmVoucher.bind(this);

        this.state = {
            cardname: "",
            TopUpAmount: 10,
            TopUpSeconds: 6667,
            TopUpRate: 9,
            ConfirmPayment: false,
            ConfirmVoucher: false,
            processingpayment: false,
            processingvoucher: false
        };
    }

    errormessages = {
        submission: "",
    }

    static contextType = StateContext;

    componentDidMount() {

        const [{ IsLoggedIn, _authtoken }] = this.context;

        if (!IsLoggedIn || !_authtoken) {
            this.props.history.push('/login');
        }
    }

    async makePayment(ev) {

        this.PaymentLock(true);

        this.setState({
            fromError: false,
            ConfirmPayment: true
        });

    }

    async applyVoucher(ev) {

        this.PaymentLock(true);

        this.setState({
            fromError: false,
            ConfirmVoucher: true
        });

    }

    async CancelPayment() {
        this.PaymentLock(false);
        this.setState({ ConfirmPayment: false });
    }

    async CloseSuccessfulPayment() {
        this.PaymentLock(false);
        this.setState({ PaymentSuccessful: false });
    }

    async CancelVoucher() {
        this.PaymentLock(false);
        this.setState({ ConfirmVoucher: false });
    }

    async CloseSuccessfulVoucher() {
        this.PaymentLock(false);
        this.setState({ VoucherSuccessful: false });
    }

    PaymentLock(bSwitch) {
        document.getElementById("PaymentAmount").disabled = bSwitch;
        document.getElementById("cardname").disabled = bSwitch;
        document.getElementById("paymentbutton").disabled = bSwitch;
        document.getElementById("vouchercode").disabled = bSwitch;
        document.getElementById("voucherbutton").disabled = bSwitch;
    }

    ConfirmPayment = (ev) => {

        this.setState({
            ConfirmPayment: false,
            processingpayment: true
        }, function () {
            Axios.get("/intents",
                {
                    params: {
                        amount: this.state.TopUpAmount * 100,
                        currency: "usd"
                    }
                }).then(response => {

                    if (response.data.clientsecret && response.data.clientsecret !== "") {
                        this.props.stripe.handleCardPayment(
                            response.data.clientsecret, this.state.cardNumberElement, {
                                payment_method_data: {
                                    billing_details: { name: this.state.cardname }
                                }
                            }
                        ).then((result) => {
                            if (result.error) {
                                console.log(result.error);
                                this.errormessages.submission = "Unfortunately your payment cannot be processed at this time.  Please check your card details are correct and resubmit.";
                                this.setState({
                                    formError: true,
                                    processingpayment: false
                                });
                                this.PaymentLock(false);
                            } else {
                                this.setState({
                                    PaymentSuccessful: true,
                                    processingpayment: false
                                });

                                // Submit a GA event

                                ReactGA.event({
                                    category: "Payments",
                                    action: "Card-Payment",
                                    value: this.state.TopUpAmount * 100
                                });
                            }
                        });
                    }
                    else {
                        console.log("Intent Request Error");
                        this.errormessages.submission = "Unfortunately your payment cannot be processed at this time.  Please check your card details are correct and resubmit.";
                        this.setState({
                            formError: true,
                            processingpayment: false
                        });
                        this.PaymentLock(false);
                    }

                }).catch(error => {
                    console.log("Unable to request Payment Intent");
                    this.errormessages.submission = "Unfortunately your payment cannot be processed at this time.  Please check your card details are correct and resubmit.";
                    this.setState({
                        formError: true,
                        processingpayment: false
                    });
                    this.PaymentLock(false);
                });
        });
    }

    ConfirmVoucher = (ev) => {

        this.setState({
            ConfirmVoucher: false,
            processingvoucher: true
        }, function () {

            Axios.post("/voucher/" + this.state.vouchercode,
                {
                }).then(response => {
                    if (response.status === 200) {
                        let responseJSON = response.data;

                        this.setState({
                            VoucherAmount: response.data.amountcredited,
                            VoucherSuccessful: true,
                            processingvoucher: false
                        });

                        // Submit a GA event

                        ReactGA.event({
                            category: "Payments",
                            action: "Voucher-Payment"
                        });
                    }

                }).catch(error => {
                    console.log("Unable to apply voucher");
                    this.errormessages.submission = "Unfortunately your voucher code cannot be processed at this time.  Please check your code is correct and resubmit."
                    this.setState({
                        formError: true,
                        processingvoucher: false
                    });
                    this.PaymentLock(false);
                });
        });
    }

    UpdateAmountCredit(e) {
        this.setState({
            TopUpAmount: e.target.value,
            TopUpRate: (e.target.value < 100 ? 9 : (e.target.value >= 100 && e.target.value < 1000 ? 8 : (e.target.value >= 1000 ? 7 : 9))),
            TopUpSeconds: Math.ceil(((e.target.value * 100) / (e.target.value < 100 ? 9 : (e.target.value >= 100 && e.target.value < 1000 ? 8 : (e.target.value >= 1000 ? 7 : 9))))) * 60
//            TopUpRate: (e.target.value < 100 ? 13 : (e.target.value >= 100 && e.target.value < 1000 ? 12 : (e.target.value >= 1000 ? 11 : 13))),
  //          TopUpSeconds: Math.ceil(((e.target.value * 100) / (e.target.value < 100 ? 13 : (e.target.value >= 100 && e.target.value < 1000 ? 12 : (e.target.value >= 1000 ? 11 : 13))))) * 60
        });
    }

    handleReady = (element) => {
        this.setState({ cardNumberElement: element });
    };


    UpdateState = (e) => this.setState({ [(e.target.name === "" ? e.target.id : e.target.name)]: e.target.value.trim() });

    render() {
        return (
            <>
                <Modal show={this.state.ConfirmPayment} onHide={this.CancelPayment.bind(this)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Confirm Payment</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Please confirm that you wish to process a payment for <span className="makebold">${this.state.TopUpAmount}</span>.</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.CancelPayment.bind(this)}>
                            Cancel
                         </Button>
                        <Button variant="danger" onClick={this.ConfirmPayment.bind(this)}>
                            Make Payment
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Modal show={this.state.ConfirmVoucher} onHide={this.CancelVoucher.bind(this)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Confirm Voucher</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Please confirm that you wish to apply voucher code: <span className="makebold">{this.state.vouchercode}</span> to your account.</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.CancelVoucher.bind(this)}>
                            Cancel
                         </Button>
                        <Button variant="danger" onClick={this.ConfirmVoucher.bind(this)}>
                            Apply Voucher
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Modal show={this.state.PaymentSuccessful} onHide={this.CloseSuccessfulPayment.bind(this)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Payment Confirmed</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Thank you.  Your payment for <span className="makebold">${this.state.TopUpAmount}</span> has been successful and your credit will appear on your account in a few moments.</Modal.Body>
                    <Modal.Footer>
                        <Button variant="success" onClick={this.CloseSuccessfulPayment.bind(this)}>
                            Close
                         </Button>
                    </Modal.Footer>
                </Modal>
                <Modal show={this.state.VoucherSuccessful} onHide={this.CloseSuccessfulVoucher.bind(this)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Voucher Confirmed</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Thank you.  Your voucher for <span className="makebold">{secondsToHoursMinutes(this.state.VoucherAmount)}</span> has been successful applied and your credit will appear on your account in a few moments.</Modal.Body>
                    <Modal.Footer>
                        <Button variant="success" onClick={this.CloseSuccessfulVoucher.bind(this)}>
                            Close
                         </Button>
                    </Modal.Footer>
                </Modal>
                <div className="col-md-6 border currentbalance">
                    <div className="row header">
                        <div className="col">Payments</div>
                    </div>
                    <div className="row text">
                        <div style={{ position: 'relative' }}>
                            {this.state.processingpayment &&
                                <div className="border waitpanel">
                                    <div className="waitpaneltext">
                                        <div>Please wait while we process your payment.</div>
                                    </div>
                                </div>
                            }
                            {this.state.processingvoucher &&
                                <div className="border waitpanel">
                                    <div className="waitpaneltext">
                                        <div>Please wait while we process your voucher.</div>
                                    </div>
                                </div>
                            }
                            <div className="col text">

                                <Tabs defaultActiveKey="card" id="uncontrolled-tab-example">
                                    <Tab eventKey="card" title="Pay by card">
                                        <Form className="login" noValidate validated={this.state.validated} onSubmit={this.handleSubmit}>
                                            <FormText className="text">
                                                <br />Please select your desired top-up amount and enter your credit card details.  All payment processing is done by Stripe and some payments may require additional verification to comply with the European Unions PSD2 regulation for Strong Customer Authentication (SCA) for online payments.<br /><br />
                                            </FormText>
                                            {this.state.formError && <Alert variant="danger">{this.errormessages.submission}</Alert>}
                                            <FormGroup controlId="PaymentAmount">
                                                <Row>
                                                    <Col>
                                                        <FormLabel>Amount</FormLabel>
                                                        <Form.Control as="select" onChange={this.UpdateAmountCredit.bind(this)}>
                                                            <option value="10" defaultValue>$10</option>
                                                            <option value="20">$20</option>
                                                            <option value="30">$30</option>
                                                            <option value="40">$40</option>
                                                            <option value="50">$50</option>
                                                            <option value="60">$60</option>
                                                            <option value="70">$70</option>
                                                            <option value="80">$80</option>
                                                            <option value="90">$90</option>
                                                            <option value="100">$100</option>
                                                            <option value="250">$250</option>
                                                            <option value="500">$500</option>
                                                        </Form.Control>
                                                        <Form.Control.Feedback type="invalid">Please enter your first name</Form.Control.Feedback>
                                                        <FormText className="text-muted">All payments are in U.S. Dollars</FormText>
                                                    </Col>
                                                    <Col>
                                                        <FormLabel>&nbsp;</FormLabel>
                                                        <FormText>equals {secondsToHoursMinutes(this.state.TopUpSeconds)} at a rate of <b>{this.state.TopUpRate}c</b> per minute.</FormText>
                                                    </Col>
                                                </Row>
                                            </FormGroup>
                                            <FormGroup controlId="cardname">
                                                <Row>
                                                    <Col>
                                                        <FormLabel>Name on card</FormLabel>
                                                        <FormControl placeholder="Name on card" required onChange={this.UpdateState} />
                                                        <Form.Control.Feedback type="invalid">Please enter the name as it appears on the credit card.</Form.Control.Feedback>
                                                    </Col>
                                                </Row>
                                            </FormGroup>

                                            <FormGroup controlId="CardNumberGroup">
                                                <FormLabel>Credit Card Number</FormLabel>
                                                <InputGroup>
                                                    <span className="fullwidth">
                                                        <CardNumberElement onReady={this.handleReady} />
                                                    </span>
                                                </InputGroup>
                                            </FormGroup>
                                            <FormGroup controlId="CardExpiryGroup">
                                                <Row>
                                                    <Col>
                                                        <FormLabel>Expiry Date</FormLabel>
                                                        <InputGroup>
                                                            <span className="fullwidth">
                                                                <CardExpiryElement />
                                                            </span>
                                                        </InputGroup>
                                                    </Col>
                                                    <Col>
                                                        <FormLabel>CVV Number</FormLabel>
                                                        <InputGroup>
                                                            <span className="fullwidth">
                                                                <CardCvcElement />
                                                            </span>
                                                        </InputGroup>
                                                    </Col>
                                                </Row>
                                            </FormGroup>
                                            <FormGroup controlId="ButtonGroup">
                                                <Button id="paymentbutton" variant="primary" onClick={this.makePayment}>Make Payment</Button>
                                            </FormGroup>
                                        </Form>
                                    </Tab>
                                    <Tab eventKey="voucher" title="Redeem a voucher">
                                        <Form className="login" noValidate validated={this.state.validated} onSubmit={this.handleSubmit}>
                                            <FormText className="text">
                                                <br />Please enter your voucher code and press the 'Apply Voucher' button.  If the code is valid then the additional credit will be added to your balance in a few moments.<br /><br />
                                            </FormText>
                                            {this.state.formError && <Alert variant="danger">{this.errormessages.submission}</Alert>}
                                            <FormGroup controlId="vouchercode">
                                                <Row>
                                                    <Col>
                                                        <FormLabel>Voucher Code</FormLabel>
                                                        <FormControl name="vouchercode" placeholder="Voucher Code" required onChange={this.UpdateState} />
                                                        <Form.Control.Feedback type="invalid">Please enter the voucher code.</Form.Control.Feedback>
                                                    </Col>
                                                </Row>
                                            </FormGroup>
                                            <FormGroup>
                                                <Button id="voucherbutton" variant="primary" onClick={this.applyVoucher}>Apply Voucher</Button>
                                            </FormGroup>
                                        </Form>
                                    </Tab>
                                </Tabs>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default injectStripe(Checkout);