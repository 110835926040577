import React, { Component } from 'react'

import { StateContext } from '../../context/AppContext';
import { withRouter, Link } from 'react-router-dom';

import { FaLock, FaAt } from 'react-icons/fa';

import Alert from 'react-bootstrap/Alert';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import FormGroup from 'react-bootstrap/FormGroup';
import InputGroup from 'react-bootstrap/InputGroup';
import FormLabel from 'react-bootstrap/FormLabel';
import FormText from 'react-bootstrap/FormText';
import Button from 'react-bootstrap/Button';
import Loader from 'react-loader-spinner';
import { FormCheck } from 'react-bootstrap';

import Axios from 'axios'
import Recaptcha from 'react-google-invisible-recaptcha';

import { Helmet } from "react-helmet";
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import ReactGA from "react-ga4";

export default class Register extends Component {
    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onResolved = this.onResolved.bind(this);
        this.onCaptchaError = this.onCaptchaError.bind(this);
    }

    static contextType = StateContext;

    state = {
        username: "",
        email: "",
        password: "",
        confirmpassword: "",
        forename: "",
        surname: "",
        company: "",
        country: "",
        validated: false,
        submitting: false,
        newsletter: false,
        recaptcha: "",
        fingerprint: ""
    }

    errormessages = {
        submission: "",
        username: "",
        email: "",
        password: "Please enter a valid password.",
        confirmpassword: "Please enter a valid password.",
        forename: "",
        surname: "",
        company: "",
        country: "",
        newsletter: ""
    }

    UpdateState = (e) => this.setState({ [e.target.name]: e.target.value.trim() });

    UpdateNewsletter = (e) => {
        this.setState({ newsletter: e.target.checked });
    };

    customValidityCheck(e) {

        var bResult = true;
        var PWPattern1 = /[0-9]/;
        var PWPattern2 = /[a-z]/;
        var PWPattern3 = /[A-Z]/;

        if (this.refs.password.value !== this.refs.confirmpassword.value) {
            this.refs.password.value = "";
            this.refs.confirmpassword.value = "";
            this.errormessages.password = "Your password must match your confirmation password.";
            bResult = false;
        }
        else {
            if (this.state.password.length < 8 || !PWPattern1.test(this.state.password) || !PWPattern2.test(this.state.password) || !PWPattern3.test(this.state.password) || this.state.password.toLowerCase().indexOf(this.state.forename.toLowerCase()) > -1 || this.state.password.toLowerCase().indexOf(this.state.surname.toLowerCase()) > -1 || this.state.password.toLowerCase().indexOf(this.state.email.substring(0, this.state.email.indexOf("@")).toLowerCase()) > -1) {
                this.refs.password.value = "";
                this.refs.confirmpassword.value = "";
                this.errormessages.password = "Your password does not meet minimum requirements.";
                bResult = false;
            }
            else {
                this.errormessages.password = "Please enter a valid password.";
            }
        }

        return bResult;
    }

    onCaptchaError() {

        document.getElementById("submitbutton").disabled = false;
        this.errormessages.submission = "Error on reCaptcha";

        this.setState({
            formError: true
        });
    }

    onResolved() {
        this.setState({
            submitting: true,
            recaptcha: this.recaptcha.getResponse()
        }, function () {
            // Submit the request to create a new user.

            document.getElementById("submitbutton").disabled = true;

            // Initialize the fingerprinting

            //const fpPromise = FingerprintJS.load({
              //  apiKey: process.env.FINGERPRINT_TOKEN, region: process.env.FINGERPRINT_REGION, endpoint: process.env.FINGERPRINT_BASE
            //});

            //const fpPromise = FingerprintJS.load({
            //    apiKey: "oDVeN4ETHc5Jhq19YKLT",
            //    region: "eu",
            //    endpoint: "https://security-fp.3scri.be"
            //});

            const fpPromise = FingerprintJS.load();
            
            // Get the visitor identifier when you need it.
            fpPromise
                .then(fp => fp.get())
                .then(result => {

                    this.setState({ fingerprint: result.visitorId }, () => {

                        Axios.post("/account", JSON.stringify(this.state), {
                            timeout: 180000
                        }).then(response => {

                            console.log(response);
                            
                            // Submit a GA event

                            ReactGA.event({
                                category: "Account",
                                action: "Registered"
                            });

                            document.getElementById("submitbutton").disabled = false;
                            this.props.history.push('/registerconfirm');
                        }).catch(error => {

                            if (error.response) {
                                  console.log(error.response.status);
                                  console.log(error.response.statusText);
                                  console.log(error.message);
                                  console.log(error.response.headers); 
                                  console.log(error.response.data); 
                                
                                document.getElementById("submitbutton").disabled = false;

                                //if (typeof error.response.data === "undefined") { this.errormessages.submission = "Error"; }
                                //else {
                                //    this.errormessages.submission = error.response.data.errormessage;
                                //}
                                this.setState({ formError: true, submitting: false });
                            }
                            else
                            {
                                this.props.history.push('/registerconfirm');
                            }
                        });
                    });
                });
        });

        document.getElementById("submitbutton").disabled = false;
        this.setState({ validated: false });
    };

    handleSubmit(event) {
        try {
            const form = event.currentTarget;
            if (this.customValidityCheck(event) === false || form.checkValidity() === false) {

                this.setState({ validated: true });
                event.preventDefault();
                event.stopPropagation();
            }
            else {
                this.recaptcha.execute();
                event.preventDefault();
                event.stopPropagation();
            }
        }
        catch
        {
            event.preventDefault();
            event.stopPropagation();
        }
    };

    render() {

        const [{ recaptcha_key }] = this.context;

        return (
            <>
                <Helmet>
                    <title>Register | 3Scribe</title>
                </Helmet>
                <div className="row smallfillerpanel">
                </div>
                <div className="row justify-content-center">
                    <div className="loginpanel border col-md-6 col-lg-5 col-sm-4">
                        <Form className="login" noValidate validated={this.state.validated} onSubmit={this.handleSubmit}>
                            <FormText className="box-header">
                                Welcome to <span className="boxHeader companycolor">3Scribe</span>
                            </FormText>
                            <FormText className="box-text">
                                <b>Start a free trial today</b><br /><br />
                                Sign up to access the most accurate automated transcription service on the market.  Don't believe us? We'll add <b>30 minutes free credit</b> to your account so you can judge us for yourself.<br /><br />
                                Already have an account?&nbsp;&nbsp;You can log-in <Link to="/login">here.</Link>
                            </FormText>
                            {this.state.formError && <Alert variant="danger">{this.errormessages.submission}</Alert>}
                            <FormGroup controlId="NameGroup">
                                <Row>
                                    <Col>
                                        <FormLabel>First name</FormLabel>
                                        <FormControl name="forename" placeholder="First name" autoFocus="autofocus" onChange={this.UpdateState} required ref="forename" />
                                        <Form.Control.Feedback type="invalid">Please enter your first name</Form.Control.Feedback>
                                    </Col>
                                    <Col>
                                        <FormLabel>Surname</FormLabel>
                                        <FormControl name="surname" placeholder="Surname" required onChange={this.UpdateState} />
                                        <Form.Control.Feedback type="invalid">Please enter your surname</Form.Control.Feedback>
                                    </Col>
                                </Row>
                            </FormGroup>

                            <FormGroup controlId="EmailAddressGroup">
                                <FormLabel>Email address</FormLabel>
                                <InputGroup>
                                    <InputGroup.Prepend>
                                        <InputGroup.Text id="inputGroupPrepend"><FaAt className="companycolour" /></InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <FormControl name="email" type="email" placeholder="Email" required onChange={this.UpdateState} />
                                    <Form.Control.Feedback type="invalid">Please enter a valid email address</Form.Control.Feedback>
                                </InputGroup>
                            </FormGroup>

                            <FormGroup controlId="NewsletterGroup">
                                <FormCheck name="newsletter" checked={this.state.newsletter} onChange={this.UpdateNewsletter} type="checkbox" label="Check this box if you would like to receive our newsletter" />
                            </FormGroup>

                            <FormGroup controlId="PasswordGroup">
                                <FormLabel>Password</FormLabel>
                                <InputGroup>
                                    <InputGroup.Prepend>
                                        <InputGroup.Text id="inputGroupPrepend"><FaLock className="companycolour" /></InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <FormControl name="password" ref="password" type="password" placeholder="***********" required onChange={this.UpdateState} />
                                    <Form.Text className="text-muted">
                                        Your password should be at least 8 characters long, include at least 1 uppercase (A-Z), 1 lowercase (a-z) letter and a digit (0-9).  It may not contain your first name, surname or email username.
                                    </Form.Text>
                                    <Form.Control.Feedback type="invalid">{this.errormessages.password}</Form.Control.Feedback>
                                </InputGroup>
                            </FormGroup>
                            <FormGroup controlId="PasswordVerifyGroup">
                                <FormLabel>Verify your Password</FormLabel>
                                <InputGroup>
                                    <InputGroup.Prepend>
                                        <InputGroup.Text id="inputGroupPrepend"><FaLock className="companycolour" /></InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <FormControl name="confirmpassword" ref="confirmpassword" type="password" placeholder="***********" required onChange={this.UpdateState} />
                                    <Form.Control.Feedback type="invalid">{this.errormessages.password}</Form.Control.Feedback>
                                </InputGroup>
                                <FormText className="text-muted">
                                    By creating an account, you agree to our <Link to="/privacypolicy">Privacy Policy</Link> and our <Link to="/termsofservice">Terms of Service</Link>
                                </FormText>
                            </FormGroup>

                            <Recaptcha
                                ref={ref => this.recaptcha = ref}
                                sitekey={recaptcha_key}
                                onResolved={this.onResolved}
                                onError={this.onCaptchaError} />

                            <Button id="submitbutton" variant="primary" type="submit" className="RegisterButton">{this.state.submitting && <Loader type="Watch" color="#ffffff" height={30} width={30} />}{!this.state.submitting && <span id="submitText">Create my account</span>}</Button>
                        </Form>
                    </div>
                </div>
                <div className="row fillerpanel"></div>
            </>
        );
    }
}
