import React, { Component } from 'react';
import Axios from 'axios';

import { StateContext } from '../../context/AppContext';
import { withRouter, Link } from 'react-router-dom'

import Alert from 'react-bootstrap/Alert';

import { FaLock, FaAt } from 'react-icons/fa';

import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import FormGroup from 'react-bootstrap/FormGroup';
import InputGroup from 'react-bootstrap/InputGroup';
import FormLabel from 'react-bootstrap/FormLabel';
import FormText from 'react-bootstrap/FormText';
import Button from 'react-bootstrap/Button';

import queryString from 'query-string'

import { Helmet } from "react-helmet";

const auth0 = require("auth0-js");
const jwt_decode = require('jwt-decode');

class Login extends Component {

    constructor(props) {
        super(props);
    }

    static contextType = StateContext;

    UpdateState = (e) => this.setState({ [e.target.name]: e.target.value.trim() });

    state = {
        EmailAddress: '',
        Password: '',
        erroralert: false,
        unverifiederroralert: false
    };

    componentDidMount() {

        const [{ IsLoggedIn }, dispatch] = this.context;

        // If the user was directed here as part of a pages auth check then redirect back to the page when the user has logged in. 

        const params = queryString.parse(this.props.location.search);

        var querystring = '';
        var redirecturi = 'jobs/';

        localStorage.setItem('redirecturi', redirecturi);

        if (this.props.location.search !== null && this.props.location.search !== '') {
            querystring = '';

            Object.keys(params).forEach(function (key) {
                if (key === 'redirecturi') {
                    redirecturi = params[key];
                    localStorage.setItem('redirecturi', redirecturi);
                }
                else {
                    querystring = querystring + (querystring === '' ? '?' : '&') + key + '=' + params[key];
                }
            });

            localStorage.setItem('querystring', querystring);
        }

        if (this.props.redirectoverride && this.props.redirectoverride !== null && this.props.redirectoverride !== '') {
            redirecturi = this.props.redirectoverride;
            localStorage.setItem('redirecturi', redirecturi);
        }

        this.setState({
            ClientID: process.env.REACT_APP_AUTH0_CLIENTID,
            Domain: process.env.REACT_APP_AUTH0_DOMAIN
        });

        // If the user is already logged in then remove the credentials.

        if (IsLoggedIn) {
            var webAuth = new auth0.WebAuth({
                domain: process.env.REACT_APP_AUTH0_DOMAIN,
                clientID: process.env.REACT_APP_AUTH0_CLIENTID,
                responseType: "id_token",
                redirectUri: window.location.origin.toString() + "/login"
            });

            // Clear the Local Storage

            dispatch({
                parameter: '_authtoken',
                newValue: ""
            });

            dispatch({
                parameter: 'IsLoggedIn',
                newValue: false
            });

            dispatch({
                parameter: '_username',
                newValue: ""
            });

            dispatch({
                parameter: '_billingtype',
                newValue: 0
            });

            dispatch({
                parameter: '_gravitar',
                newValue: ""
            });

            // Remove the token from Axios

            Axios.defaults.headers.common['Authorization'] = "";

            // Remove the JWT from the central storage

            webAuth.logout({
                returnTo: window.location.origin.toString() + "/login",
                clientID: process.env.REACT_APP_AUTH0_CLIENTID
            });
        }

        if (this.props.location.hash) {

            const hashValues = queryString.parse(this.props.location.hash);

            if (hashValues.error_description && hashValues.error_description === "EMAIL_UNVERIFIED") {
                this.setState({ 'unverifiederroralert': true });
            }

            if (hashValues.loginerror) {
                this.setState({ 'erroralert': true });
            }

            if (hashValues.id_token) {

                try {
                    var decoded = jwt_decode(hashValues.id_token);

                    // Update the Axios object to use the bearer.

                    Axios.defaults.headers.common['Authorization'] = "Bearer " + hashValues.id_token;

                    // Store the token from authResult for later use

                    dispatch({
                        parameter: '_authtoken',
                        newValue: hashValues.id_token
                    });

                    dispatch({
                        parameter: '_username',
                        newValue: decoded.nickname
                    });

                    dispatch({
                        parameter: '_gravitar',
                        newValue: decoded.picture
                    });

                    dispatch({
                        parameter: '_billingtype',
                        newValue: 0
                    });

                    dispatch({
                        parameter: 'IsLoggedIn',
                        newValue: true
                    });

                    // Redirect if necessary with an saved query string.

                    redirecturi = localStorage.getItem('redirecturi');
                    querystring = localStorage.getItem('querystring');

                    if (!redirecturi || redirecturi === null) redirecturi = '';
                    if (!querystring || querystring === null) querystring = '';

                    localStorage.setItem('redirecturi', '');
                    localStorage.setItem('querystring', '');

                    this.props.history.push('/' + redirecturi + querystring);
                }
                catch (err) {

                    console.log(err);
                    this.props.history.push('/login/#loginerror');
                }
            }
        }

    };

    processAuthorization = event => {

        this.setState({
            'erroralert': false,
            'unverifiederroralert': false
        });

        // Build the redirect string

        var redirecturi = localStorage.getItem('redirecturi');
        var querystring = localStorage.getItem('querystring');

        if (!querystring || querystring === null) querystring = this.props.location.search;
        if (!querystring || querystring === null) querystring = "";

        if (querystring.length > 0 && querystring.substr(0, 1) !== "?") { querystring = "?" + querystring; }
        if (redirecturi && redirecturi !== "") { querystring = querystring + (querystring && querystring !== "" ? "&" : "?") + "redirecturi=" + redirecturi; }

        localStorage.setItem('redirecturi', '');
        localStorage.setItem('querystring', '');

        // Initializing our Auth0 SDK

        var webAuth = new auth0.WebAuth({
            domain: this.state.Domain,
            clientID: this.state.ClientID,
            //audience: "https://auth0-jwt-authorizer",
            responseType: "id_token",            
            redirectUri: window.location.origin.toString() + "/login" + querystring
        });

        webAuth.login({
            email: this.state.EmailAddress,
            password: this.state.Password,
            realm: 'Username-Password-Authentication'
        }, (err, authToken) => {

            console.dir(err);


            this.setState({ 'erroralert': true }, function () { });
        });

        event.preventDefault();
    }

    render() {
        return (
            <>
                <Helmet>
                    <title>Login | 3Scribe</title>
                </Helmet>
                <div className="row fillerpanel">
                </div>
                <div className="row justify-content-center">
                    <div className="loginpanel border col-sm-4 col-md-4 col-lg-4 col-xl-3">
                        <Form className="login" onSubmit={this.processAuthorization.bind(this)}>
                            <FormText className="box-header">
                                Welcome back to <span className="boxHeader companycolor">3Scribe</span>
                            </FormText>
                            <FormText className="box-text">
                                Don't have an account?&nbsp;&nbsp;You can register <Link to="/register">here.</Link><br />
                                <Link to="/forgottenpassword">Forgot your password</Link> or <Link to="/unverifiedemail">need to resend your email verification?</Link>
                            </FormText>

                            {this.state.erroralert &&
                                <Alert variant="danger">
                                    Unable to log in! Please check your email address and password.
                                </Alert>
                            }
                            {this.state.unverifiederroralert &&
                                <Alert variant="danger">
                                    Unable to log in as your email address is not yet verified.  Please follow the link in the email you received when you registered or click <Link to="/unverifiedemail">here</Link> to have the email resent.
                                </Alert>
                            }

                            <FormGroup controlId="EmailAddressGroup">
                                <FormLabel>Email address</FormLabel>
                                <InputGroup>
                                    <InputGroup.Prepend>
                                        <InputGroup.Text id="inputGroupPrepend"><FaAt className="companycolour" /></InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <FormControl name="EmailAddress" type="email" placeholder="Enter email" autoFocus="autofocus" onChange={this.UpdateState} />
                                </InputGroup>
                            </FormGroup>

                            <FormGroup controlId="PasswordGroup">
                                <FormLabel>Password</FormLabel>
                                <InputGroup>
                                    <InputGroup.Prepend>
                                        <InputGroup.Text id="inputGroupPrepend"><FaLock className="companycolour" /></InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <FormControl name="Password" type="password" placeholder="Password" onChange={this.UpdateState} />
                                </InputGroup>
                                <FormText className="text-muted">
                                    By logging in, you agree to our <Link to="/privacypolicy">Privacy Policy</Link> and our <Link to="/termsofservice">Terms of Service</Link>
                                </FormText>
                            </FormGroup>
                            <Button type="submit" variant="primary">Login</Button>
                        </Form>
                    </div>
                </div>
                <div className="row fillerpanel"></div>
                <div className="row fillerpanel"></div>
            </>
        );
    }
}

export default withRouter(Login);