import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { StateContext } from '../context/AppContext';
import Axios from 'axios';
import { secondsToHoursMinutes } from '../globals/text';

class CurrentBalance extends Component {

    constructor(props) {
        super(props)
    }

    static contextType = StateContext;

    state = {
        BillingType: 0,
        CurrentBalance: 0,
        CurrentBalanceFormat: "incredit"
    };

    componentDidMount() {

        const [{ IsLoggedIn, _authtoken }] = this.context;

        if (!IsLoggedIn || !_authtoken) {
            this.props.history.push('/login');
        }

        this.LoadBalance();
    }

    LoadBalance() {

        const [{ IsLoggedIn }, dispatch] = this.context;

        Axios.get("/account", {
        }).then(response => {
            let responseJSON = response.data;

            this.setState({
                "BillingType": response.data.billingtype,
                "CurrentBalance": response.data.currentbalance,
                "CurrentBalanceFormat": (response.data.currentbalance > 0 ? "incredit" : "nocredit")
            });

            dispatch({
                parameter: '_billingtype',
                newValue: response.data.billingtype
            });

            dispatch({
                parameter: '_DefaultJobLanguage',
                newValue: this.state.defaultjoblanguage
            });

        }).catch((error) => {
            if (error.message === "Operation canceled due to invald token.") this.props.history.push('/login');
            this.setState(this.state);
        });
    }

    render() {
        return (
            this.state.BillingType == 0 &&
                <div className="col-md-5 border currentbalance">
                    <div className="row header">
                        <div className="col">Current Balance</div>
                    </div>
                    <div className="row text">
                        <div className="col text">You currently have credit for <span className={this.state.CurrentBalanceFormat}>{secondsToHoursMinutes(this.state.CurrentBalance, "no amount")}</span> of transcription.</div>
                    </div>
                </div>
        );
    }
}

export default withRouter(CurrentBalance);
