import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import { StateContext } from '../context/AppContext';

import { Helmet } from "react-helmet";

class Policies extends Component {

    static contextType = StateContext;

    state = {
        language: 'en'
    }

    pagetitle = "";

    loader(w, d) {
        var s = d.createElement("script"), tag = d.getElementsByTagName("script")[0];
        s.src = "https://cdn.iubenda.com/iubenda.js";
        tag.parentNode.insertBefore(s, tag);
    };

    componentDidMount() {
        if (this.props.pagetitle) {
            this.pagetitle = this.props.pagetitle;
        }

        this.loader(window, document);
    };

    render() {
        return (
            <>
                {this.pagetitle !== "" &&
                    <Helmet>
                        <title>{this.pagetitle} | 3Scribe</title>
                    </Helmet>
                }
                <div className="PolicyContainer">
                    <a href={'https://www.iubenda.com/' + this.props.policyname + '/' + process.env.REACT_APP_IUBENDA_POLICYID} className="iubenda-white iubenda-noiframe iubenda-embed iubenda-noiframe iub-body-embed" title={this.props.policytitle}>{this.props.policytitle}</a>
                </div>
            </>
        );
    }
}

export default withRouter(Policies);