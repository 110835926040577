import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import ReactMarkdown from 'react-markdown/with-html';
import Axios from 'axios';
import { Helmet } from "react-helmet";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Markdown from './Markdown';
import { BrowserRouter as Router, Route, Link, Switch } from 'react-router-dom';

class KnowledgeBase extends Component {

    state = {
        language: 'en',
        articledata: [],
        category: null,
        section: null,
        article: null
    }

    pagetitle = "";

    static getDerivedStateFromProps(props, state) {

        const { match: { params } } = props;

        return {
            language: (!params.language ? 'en' : params.language),
            category: params.category,
            section: params.section,
            article: params.article
        };
    }

    componentDidMount() {

        const { match: { params } } = this.props;

        // Load the Knowledge base articles

        const axiosInstance = Axios.create({
            baseURL: window.location.protocol + "//" + window.location.hostname + ":" + window.location.port
        });

        axiosInstance.get("articles/" + this.state.language + "/KnowledgeBase.json", {
            headers: {
                'Content-Type': "json"
            }
        }).then(response => {
            this.setState({ articledata: response.data });
        }).catch((error) => {
            console.log(error);
        });
    }

    render() {
        return (
            <>
                {this.pagetitle !== "" &&
                    <Helmet>
                        <title>{this.pagetitle} | 3Scribe</title>
                    </Helmet>
                }
                <div className="KnowledgeBaseWrapper">
                    <div className="BreadcrumbsWrapper">
                        <Breadcrumb className="Breadcrumbs">
                            <Breadcrumb.Item><Link to="/helpcenter">Help Center</Link></Breadcrumb.Item>
                            <Breadcrumb.Item><Link to="/knowledgebase">Knowledge Base</Link></Breadcrumb.Item>
                            {(this.state.category != null && <Breadcrumb.Item><Link to={'/knowledgebase/' + this.state.language + '/' + this.state.category}>{this.state.category}</Link></Breadcrumb.Item>)}
                            {(this.state.section != null && <Breadcrumb.Item><Link to={'/knowledgebase/' + this.state.language + '/' + this.state.category + '/' + this.state.section}>{this.state.section}</Link></Breadcrumb.Item>)}
                            {(this.state.article != null && <Breadcrumb.Item><Link to={'/knowledgebase/' + this.state.language + '/' + this.state.category + '/' + this.state.section + '/' + this.state.article}>{this.state.article}</Link></Breadcrumb.Item>)}
                        </Breadcrumb>
                    </div>

                    {((this.state.category == null && this.state.section == null && this.state.article == null) &&

                        <div className="CategoriesWrapper">
                            {(this.state.articledata.map(item => (item.publish &&
                                <div className="CategoryList" key={item.category}>
                                    <h1>{item.category}</h1>
                                    {(item.description && false && <div className="CategoryListDescription">{item.description}</div>)}
                                    <ul className="ArticleList">
                                        {
                                            item.sections.map((subitem, sectionindex) => 
                                                (sectionindex === 3 && item.sections.length > 4 && subitem.publish ?
                                                    <li key={subitem.section} className="">
                                                        <Link className="ViewAll" to={{
                                                            pathname: '/knowledgebase/' + this.state.language + '/' + item.category
                                                        }}>View All</Link></li> :
                                                    (sectionindex <= 3 && subitem.publish && <li key={subitem.section} className="">
                                                        <Link className="Link" to={{
                                                            pathname: '/knowledgebase/' + this.state.language + '/' + item.category + '/' + subitem.section
                                                        }}>{subitem.section}</Link>
                                                    </li>)
                                                )
                                            )
                                        }
                                    </ul>
                                </div>
                            )))}
                        </div>

                    )}

                    {((this.state.category != null && this.state.section == null && this.state.article == null) &&
                        <div className="CategoryWrapper">
                            <div className="CategoryName">
                                <h1>{this.state.category}</h1>
                            </div>
                            {(this.state.articledata.map(item => (item.category === this.state.category &&
                                <div className="SectionsList">
                                    {item.sections.map((subitem, sectionindex) => (subitem.publish &&
                                        <div key={subitem.section} className="SectionArticleList">
                                            <h2 spacing="half" className="">{subitem.section}</h2>
                                            <ul>
                                                {subitem.articles.map((articleitem, articleindex) => (articleitem.publish &&
                                                    <li key={articleitem.article} className="">
                                                        <Link className="Link" to={{
                                                        pathname: '/knowledgebase/' + (articleitem.reference ? articleitem.reference : this.state.language + '/' + item.category + '/' + subitem.section + '/' + articleitem.article)
                                                    }}>{articleitem.article}</Link>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    ))}
                                </div>
                            )))}
                        </div>
                    )}

                    {((this.state.category != null && this.state.section != null && this.state.article == null) &&
                        <div className="SectionWrapper">
                            <div className="SectionName">
                                <h1>{this.state.section}</h1>
                            </div>
                            {(this.state.articledata.map(item => (item.category === this.state.category &&
                                (item.sections.map((subitem, sectionindex) => (subitem.section === this.state.section &&
                                    <div className="ArticleList">
                                        {(subitem.articles.map((articleitem, articleindex) => (articleitem.publish &&
                                            <ul key={articleitem.article}>
                                                <li className="">
                                                <Link className="Link" to={{
                                                    pathname: '/knowledgebase/' + (articleitem.reference ? articleitem.reference : this.state.language + '/' + item.category + '/' + subitem.section + '/' + articleitem.article)
                                                }}>{articleitem.article}</Link>
                                                </li>
                                            </ul>
                                        )))}
                                    </div>
                                ))))))}
                        </div>
                    )}

                    {((this.state.category != null && this.state.section != null && this.state.article != null) &&

                        <div className="Article">
                            <Markdown {...this.props} />
                        </div>
                    )}
                </div>
            </>
        );
    }
}

export default withRouter(KnowledgeBase);