import React, { Component } from 'react'
import CurrentBalance from '../../components/CurrentBalance';
import Checkout from '../../components/Checkout';
import { withRouter, Link } from 'react-router-dom';

import {
    CardElement,
    injectStripe,
    StripeProvider,
    Elements,
} from 'react-stripe-elements';

//import Button from 'react-bootstrap/Button';
//import Form from 'react-bootstrap/Form';
//import Col from 'react-bootstrap/Col';
import Select from 'react-select'
import countryList from 'react-select-country-list'
import Axios from 'axios'

import { StateContext } from '../../context/AppContext';

class Payments extends Component {

    static contextType = StateContext;

    state = {
        username: "",
        email: "",
        password: "",
        confirmpassword: "",
        forename: "",
        surname: "",
        company: "",
        country: ""
    }

    errormessages = {
        submission: "",
        username: "",
        email: "",
        password: "",
        confirmpassword: "",
        forename: "",
        surname: "",
        company: "",
        country: ""
    }

    componentDidMount() {
        const [{ IsLoggedIn, _authtoken }, dispatch] = this.context;

        if (!IsLoggedIn || !_authtoken) {
            this.setState({ IsLoggedIn: false });
            this.props.history.push('/login?redirecturi=payments');
        }
        else {
            this.setState({ IsLoggedIn: true });
        }
    };

    LoadAccountDetails() {

        Axios.get("https://dashboard.3scri.be/account", {
            headers: {
                "Content-Type": "application/json",
                'Authorization': "Bearer " + this.props.authtoken
            }
        }).then(response => {

            console.log(response);

            this.setState({
                username: response.data.username,
                email: response.data.email,
                forename: response.data.forename,
                surname: response.data.surname,
                company: response.data.company,
                country: response.data.country
            });
        }).catch((error) => {

            console.log(error);

            if (typeof error.response === "undefined" || typeof error.response.data === "undefined") { this.errormessages.submission = "Error"; }
            else {
                //    this.errormessages.submission = error.response.data.errormessage;
            }
            this.setState(this.state);
        });

    };

    UpdateState = (e) => this.setState({ [e.target.name]: e.target.value.trim() });
    UpdateCountry = (e) => this.setState({ country: e.value });

    Validate() {

        var bResult = true;
        var EmailPattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if (this.state.forename === "") { this.errormessages.forename = "Please enter your forename."; bResult = false; } else { this.errormessages.forename = ""; }
        if (this.state.surname === "") { this.errormessages.surname = "Please enter your surname."; bResult = false; } else { this.errormessages.surname = ""; }
        if (this.state.country === "") { this.errormessages.country = "Please select a country."; bResult = false; } else { this.errormessages.country = ""; }

        if (this.state.email === "") { this.errormessages.email = "An email address is required."; bResult = false; }
        else
            if (!EmailPattern.test(this.state.email)) { this.errormessages.email = "A valid email address is required."; bResult = false; }
            else { this.errormessages.email = ""; }

        this.setState(this.state);
        return bResult;
    };

    render() {

        const [{ stripe_key }] = this.context;

        return (
            <StripeProvider apiKey={stripe_key}>

                <div className="container-fluid">

                    <div className="row justify-content-center">
                        <div className="col-md-10 col-lg-10 justify-content-center">
                            <div className="smallfillerpanel" />
                            <div className="row justify-content-left">
                                <CurrentBalance />
                            </div>
                            <div className="smallfillerpanel" />
                            <div className="row justify-content-left">
                                <div className="col-md-5 border currentbalance">
                                    <div className="row header">
                                        <div className="col">Rates</div>
                                    </div>
                                    <div className="row text">
                                        <div className="col text">
                                            <div className="smallertext">
                                                Our pricing starts at 9c per minute of transcription with a reduced cost available for larger top-ups.  All transcription jobs are rounded up to the nearest minute.  If you are a corporate user with larger volumes please <Link to="/contactus">contact us</Link> to discuss further discounts and invoiced payments.  All payments are processed via <a href="https://stripe.com">Stripe</a> and no card details are stored on our system.  Payments are converted into credit upon successful authorisation and will never expire.
                                    </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-1"></div>
                                <Elements>
                                    <Checkout />
                                </Elements>
                            </div>
                            <div className="smallfillerpanel" />
                        </div>
                    </div>

                    <div className="fillerpanel"></div>
                </div>
            </StripeProvider>
        );
    }
}

export default Payments;