import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom'
import { StateContext } from '../context/AppContext';

import { FaLinkedin, FaGithubSquare, FaTwitterSquare, FaFacebookSquare } from 'react-icons/fa';

class Footer extends Component {

    static contextType = StateContext;

    state = {
    }


    render() {

        const [{ IsLoggedIn, InDevelopment }] = this.context;

        return (

            <footer className="footer-3scribe">
                <div className="container text-lg-left">
                    <div className="row d-flex justify-content-between flex-column flex-lg-row align-items-center align-items-lg-start">
                        <div className="d-flex flex-column col-10 col-lg-5 pl-0">
                            <h5 className="footerlogo">
                                <img
                                    alt="logo"
                                    src={window.location.protocol + '//' + window.location.host + '/logo-white.svg'}
                                    width="50"
                                    height="50"
                                    className="d-inline-block align-center"
                                />
                                &nbsp;3Scribe
                            </h5>
                            <p data-config-id="description">We've built the most accurate automated transcription engine on the market and are constantly adding new features.  Why not keep in touch by following us on social media or by signing up for our newsletter.</p>
                            <ul className="list-inline d-flex mb-3 justify-content-center justify-content-lg-start" data-config-id="icons">
                                <li className="px-1"><a target="_blank" rel="noopener noreferrer" href="https://twitter.com/threescribe"><FaTwitterSquare size="2em" /></a></li>
                                <li className="px-1"><a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/ThreeScribe"><FaFacebookSquare size="2em" /></a></li>
                                <li className="px-1"><a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/company/3scribe"><FaLinkedin size="2em" /></a></li>
                                <li className="px-1"><a target="_blank" rel="noopener noreferrer" href="https://github.com/3scribe/"><FaGithubSquare size="2em" /></a></li>
                            </ul>
                        </div>
                        <div>
                            <h6>About Us</h6>
                            <ul className="list-inline ml-0">
                                <li><Link to="/aboutus">About us</Link></li>
                                <li><Link to="/contactus">Contact us</Link></li>
                                <li><Link to="/news">News</Link></li>
                                <li><Link to="/workwithus">Jobs</Link></li>
                                {/* <li><Link to="/benchmarks">Benchmarks</Link></li> */}
                            </ul>
                        </div>
                        <div>
                            <h6>Get Started</h6>
                            <ul className="list-inline ml-">
                                {!IsLoggedIn && !InDevelopment && <li><Link to="/register">Register</Link></li>}
                                {!IsLoggedIn && <li><Link to="/login">Login</Link></li>}
                                {IsLoggedIn && !InDevelopment && <li><Link to="/logout">Logout</Link></li>}
                                <li><Link to="/pricing">Pricing</Link></li>
                                <li><Link to="/knowledgebase/en/General/Frequently%20Asked%20Questions">F.A.Q.</Link></li>
                                {/*<li><Link to="/tutorials">Tutorials</Link></li>*/}
                            </ul>
                        </div>
                        <div>
                            <h6 data-config-id="nav3-column">Developers</h6>
                            <ul className="list-inline ml-0">
                                <li><a target="_blank" rel="noreferrer" href="https://developers.3scri.be">API</a></li>
                                {/*<li><Link to="#">SDK</Link></li>*/}
                                <li><a target="_blank" rel="noreferrer" href="https://github.com/3Scribe/">GitHub</a></li>
                                <li><Link to="/integrations">Integrations</Link></li>
                            </ul>
                        </div>
                        <div className="w-100 border-top my-3"></div>
                    </div>
                    <div className="flex-column flex-sm-row align-items-center justify-content-sm-between">
                        <Link to="/termsofservice">Terms and conditions</Link>
                        <p className="mb-0 small text-muted" data-config-id="copyright">&copy; 2020 3Scribe. All right reserved.</p>
                    </div>
                </div>
            </footer>

        );
    }
}
export default withRouter(Footer);