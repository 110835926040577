import React, { Component } from 'react'

import { StateContext } from '../../context/AppContext';
import { withRouter, Link } from 'react-router-dom';

import { FaAt, FaGlobeAmericas, FaClock, FaLanguage } from 'react-icons/fa';

import Alert from 'react-bootstrap/Alert';

import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import FormGroup from 'react-bootstrap/FormGroup';
import InputGroup from 'react-bootstrap/InputGroup';
import FormLabel from 'react-bootstrap/FormLabel';
import FormText from 'react-bootstrap/FormText';
import Button from 'react-bootstrap/Button';
import Loader from 'react-loader-spinner';

import Axios from 'axios'

import countryList from 'react-select-country-list';
import { FormCheck } from 'react-bootstrap';

class ModifyAccount extends Component {

    static contextType = StateContext;

    globals = {};
    globalsdispatcher = null;

    async RefreshGlobals() {

        const [globals, dispatch] = this.context;
        this.globals = globals;
        this.globalsdispatcher = dispatch;
    };

    state = {
        settingspage: false,
        accountpage: true,
        username: "",
        email: "",
        forename: "",
        surname: "",
        country: "",
        newsletter: false,
        UpdateConfirmed: false,
        submitting: false
    }

    errormessages = {
        submission: "",
        username: "",
        email: "",
        forename: "",
        surname: "",
        country: "",
        newsletter: ""
    }

    async componentWillMount() {

        await this.RefreshGlobals();

        if (!this.globals.IsLoggedIn || !this.globals._authtoken) {
            this.props.history.push('/login?redirecturi=account');
        }
    }

    componentDidMount() {
        this.LoadAccountDetails();
    };

    LoadAccountDetails() {

        Axios.get("/account", {
        }).then(response => {
            this.setState({
                username: response.data.username,
                email: response.data.email,
                forename: response.data.forename,
                surname: response.data.surname,
                country: response.data.country,
                newsletter: response.data.newsletter,
            });
        }).catch((error) => {

            if (typeof error.response === "undefined" || typeof error.response.data === "undefined") { this.errormessages.submission = "Error"; }
            else {
                //    this.errormessages.submission = error.response.data.errormessage;
            }
            this.setState(this.state);
        });

    };

    UpdateState = (e) => this.setState({ [e.target.name]: e.target.value.trim() });

    UpdateNewsletter = (e) => {
        this.setState({ newsletter: e.target.checked });
    };

    Validate() {

        var bResult = true;
        var EmailPattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if (this.state.forename === "") { this.errormessages.forename = "Please enter your forename."; bResult = false; } else { this.errormessages.forename = ""; }
        if (this.state.surname === "") { this.errormessages.surname = "Please enter your surname."; bResult = false; } else { this.errormessages.surname = ""; }
        if (this.state.country === "") { this.errormessages.country = "Please select a country."; bResult = false; } else { this.errormessages.country = ""; }

        if (this.state.email === "") { this.errormessages.email = "An email address is required."; bResult = false; }
        else
            if (!EmailPattern.test(this.state.email)) { this.errormessages.email = "A valid email address is required."; bResult = false; }
            else { this.errormessages.email = ""; }

        this.setState(this.state);
        return bResult;
    };

    customValidityCheck(e) {

        var bResult = true;
        var PWPattern1 = /[0-9]/;
        var PWPattern2 = /[a-z]/;
        var PWPattern3 = /[A-Z]/;

        //if (this.refs.password.value !== this.refs.confirmpassword.value) {
        //    this.refs.password.value = "";
        //    this.refs.confirmpassword.value = "";
        //    this.errormessages.password = "Your password must match your confirmation password.";
        //    bResult = false;
        //}
        //else {
        //    if (this.state.password.length < 8 || !PWPattern1.test(this.state.password) || !PWPattern2.test(this.state.password) || !PWPattern3.test(this.state.password)) {
        //        this.refs.password.value = "";
        //        this.refs.confirmpassword.value = "";
        //        this.errormessages.password = "Your password should be at least 8 characters long, include at least 1 uppercase (A-Z), 1 lowercase (a-z) letter and a digit (0-9).";
        //        bResult = false;
        //    }
        //    else {
        //        this.errormessages.password = "Please enter a valid password.";
        //    }
        //}

        return bResult;
    };

    handleSubmit = event => {

        const [{ IsLoggedIn }, dispatch] = this.context;
        const form = event.currentTarget;

        if (this.customValidityCheck(event) === false || form.checkValidity() === false) {
        }
        else {
            this.setState({
                submitting: true
            }, function () {
                // Submit the request to create a new user.

                Axios.put("/account", JSON.stringify(this.state), {
                }).then(response => {
                    this.setState({ UpdateConfirmed: true, submitting: false });
                }).catch(error => {
                    if (typeof error.response.data === "undefined") { this.errormessages.submission = "Error"; }
                    else {
                        this.errormessages.submission = error.response.data.errormessage;
                    }
                    this.setState({ formError: true, submitting: false });
                });
            });
        }

        this.setState({ validated: true });
        event.preventDefault();
        event.stopPropagation();
    };

    async CloseModal() {
        this.setState({ UpdateConfirmed: false });
    };

    render() {
        return (
            <div class="page-component__bg_image_box bg-white-color  first_component   " id="text-07-345071">
                <div class="page-component__bg_overlay_box ">
                </div>
                <div style={{ position: "relative", zindex: 100 }}>
                    <Modal show={this.state.UpdateConfirmed} onHide={this.CloseModal.bind(this)}>
                        <Modal.Header closeButton>
                            <Modal.Title>Update Confirmed</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>Thank you.  Your account details have now been updated.</Modal.Body>
                        <Modal.Footer>
                            <Button variant="success" onClick={this.CloseModal.bind(this)}>
                                Close
                         </Button>
                        </Modal.Footer>
                    </Modal>
                    <div className="row smallfillerpanel">
                    </div>
                    <div className="row justify-content-center">
                        <div className="loginpanel border col-md-6 col-lg-5 col-sm-4">
                            <Form className="login" noValidate validated={this.state.validated} onSubmit={this.handleSubmit}>
                                <FormText className="box-header">
                                    Edit your account details
                                </FormText>
                                <FormText className="box-text">
                                    You can update your accounts details here {/* or click <Link to="/login">here</Link> to change default settings to streamline your workflow within 3Scribe. */}
                                </FormText>
                                {this.state.formError && <Alert variant="danger">{this.errormessages.submission}</Alert>}
                                <FormGroup controlId="NameGroup">
                                    <Row>
                                        <Col>
                                            <FormLabel>First name</FormLabel>
                                            <FormControl name="forename" placeholder="First name" autoFocus="autofocus" onChange={this.UpdateState} required ref="forename" value={this.state.forename} />
                                            <Form.Control.Feedback type="invalid">Please enter your first name</Form.Control.Feedback>
                                        </Col>
                                        <Col>
                                            <FormLabel>Surname</FormLabel>
                                            <FormControl name="surname" placeholder="Surname" required onChange={this.UpdateState} value={this.state.surname} />
                                            <Form.Control.Feedback type="invalid">Please enter your surname</Form.Control.Feedback>
                                        </Col>
                                    </Row>
                                </FormGroup>

                                <FormGroup controlId="EmailAddressGroup">
                                    <FormLabel>Email address</FormLabel>
                                    <InputGroup>
                                        <InputGroup.Prepend>
                                            <InputGroup.Text id="inputGroupPrepend"><FaAt className="companycolour" /></InputGroup.Text>
                                        </InputGroup.Prepend>
                                        <FormControl name="email" type="email" placeholder="Email" required onChange={this.UpdateState} value={this.state.email} />
                                        <Form.Control.Feedback type="invalid">Please enter a valid email address</Form.Control.Feedback>
                                    </InputGroup>
                                </FormGroup>

                                <FormGroup controlId="ChangePasswordGroup">
                                    <FormLabel>Password</FormLabel>
                                    <InputGroup>
                                        <Link to="/passwordreset">
                                            <Button variant="warning" type="button">Change my password</Button>
                                        </Link>
                                    </InputGroup>
                                </FormGroup>

                                <FormGroup controlId="CountryGroup">
                                    <FormLabel>Country</FormLabel>
                                    <InputGroup>
                                        <InputGroup.Prepend>
                                            <InputGroup.Text id="inputGroupPrepend"><FaGlobeAmericas className="companycolour" /></InputGroup.Text>
                                        </InputGroup.Prepend>
                                        <Form.Control name="country" as="select" required onChange={this.UpdateState} value={this.state.country}>
                                            {countryList().getData().map((country) => {
                                                return (
                                                    <option key={country.value} value={country.value}>{country.label}</option>
                                                );
                                            })}
                                        </Form.Control>
                                    </InputGroup>
                                </FormGroup>

                                <FormGroup controlId="NewsletterGroup">
                                    <FormLabel>Newsletter</FormLabel>
                                    <FormCheck name="newsletter" checked={this.state.newsletter} onChange={this.UpdateNewsletter} type="checkbox" label="Check this box if you would like to receive our newsletter" />
                                </FormGroup>

                                <FormGroup controlId="ClockGroup">
                                    <FormLabel>&nbsp;</FormLabel>
                                    <InputGroup>
                                        <Button id="submitbutton" variant="primary" type="submit" className="RegisterButton">{this.state.submitting && <Loader type="Watch" color="#ffffff" height={30} width={30} />}{!this.state.submitting && <span id="submitText">Update my account</span>}</Button>
                                    </InputGroup>
                                </FormGroup>
                            </Form>
                        </div>
                    </div>
                    <div className="row fillerpanel"></div>
                </div>
            </div>
        );
    }
}

export default ModifyAccount;