import React, { Component } from 'react';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import Axios from 'axios'
import { StateContext } from '../../context/AppContext';
import { FaRegCopy } from 'react-icons/fa';
import Panels from '../../components/Panels';

import ReactGA from "react-ga4";

class APIKey extends Component {

    static contextType = StateContext;

    globals = {};
    globalsdispatcher = null;

    RefreshGlobals() {

        const [globals, dispatch] = this.context;
        this.globals = globals;
        this.globalsdispatcher = dispatch;
    };

    state = {
        ConfirmReset: false,
        APIKey: "Loading"
    }

    errormessages = {}

    componentWillMount() {

        const [{ IsLoggedIn, _authtoken }, dispatch] = this.context;

        if (!IsLoggedIn || !_authtoken) {
            this.setState({ IsLoggedIn: false });
            this.props.history.push('/login?redirecturi=apikey');
        }
        else {
            this.setState({ IsLoggedIn: true });
            this.LoadAPIKey();
        }
    }

    UpdateState = (e) => this.setState({ [e.target.name]: e.target.value.trim() });

    LoadAPIKey() {

        Axios.get("/apikey", {
        }).then(response => {
            this.setState({ APIKey: response.data.apikey });
        }).catch((error) => {

            // If unauthorized then redirect to the login page

            if (error.response && error.response.status === 401) {
                this.props.history.push('/logout');
            }

            if (error.response && error.response.status === 423) {
                this.props.history.push('/unverifiedemail');
            }

            this.setState(this.state);
        });
    }

    ResetAPIKey = () => {
        this.setState({
            ConfirmReset: true
        });
    }

    CancelReset() {
        this.setState({ ConfirmReset: false });
    }

    ConfirmReset() {
        this.setState({
            APIKey: "Resetting",
            ConfirmReset: false
        }, function () {
            Axios.put("/apikey", {
            }).then(response => {
                this.setState({ APIKey: response.data.apikey });

                // Submit a GA event

                ReactGA.event({
                    category: "Settings",
                    action: "Reset-API-Key"
                });

            }).catch((error) => {
                this.setState(this.state);
            });
        });
    }

    CopyKey = () => {



    }

    render() {
        return (
            <>
                <Modal show={this.state.ConfirmReset} onHide={this.CancelReset.bind(this)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Confirm API Key Reset</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Please confirm that you wish to reset your API key.  This action cannot be reversed and may affect any applications or integrations you currently have set up.</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.CancelReset.bind(this)}>
                            Cancel
                         </Button>
                        <Button variant="danger" onClick={this.ConfirmReset.bind(this)}>
                            Reset
                        </Button>
                    </Modal.Footer>
                </Modal>
                <div className="fillerpanel"></div>
                <div className="row justify-content-center">
                    <div className="col-6 col-xl-5 apibox border">
                        <div className="row header">
                            <div className="col">Your API Key:</div>
                        </div>
                        <div className="row subheader border">
                            <div className="addflex col-12 justify-content-end">
                                <OverlayTrigger
                                    key='top'
                                    placement='top'
                                    overlay={
                                        <Tooltip id={'tooltip-clipboard'}>Copy to clipboard.</Tooltip>
                                    }>
                                    <FaRegCopy className="addpointer" onClick={() => { navigator.clipboard.writeText(this.state.APIKey) }} />
                                </OverlayTrigger>
                            </div>
                            <div className="addflex col justify-content-center">
                                {this.state.APIKey}
                            </div>
                        </div>
                        <div className="row">
                            <div className="addflex col text justify-content-end">
                                <Button onClick={() => { this.ResetAPIKey() }}>Reset</Button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="fillerpanel"></div>
                <Panels panelslist={[
                    "integrations"
                ]} />
                <div className="fillerpanel"></div>
            </>
        )
    }
}

export default APIKey;