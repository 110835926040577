import React, { Component } from 'react'

import { StateContext } from '../../context/AppContext';
import { withRouter, Link } from 'react-router-dom';

import { FaLock, FaAt } from 'react-icons/fa';

import Modal from 'react-bootstrap/Modal';
import Alert from 'react-bootstrap/Alert';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import FormGroup from 'react-bootstrap/FormGroup';
import InputGroup from 'react-bootstrap/InputGroup';
import FormLabel from 'react-bootstrap/FormLabel';
import FormText from 'react-bootstrap/FormText';
import Button from 'react-bootstrap/Button';
import Loader from 'react-loader-spinner';

import Axios from 'axios'
import ReactGA from "react-ga4";

export default class PasswordReset extends Component {

    state = {
        oldpassword: "",
        password: "",
        confirmpassword: "",
        validated: false,
        UpdateConfirmed: false,
        submitting: false
    }

    errormessages = {
        submission: "",
        oldpassword: "",
        password: "Please enter a valid password.",
        confirmpassword: "Please enter a valid password."
    }

    UpdateState = (e) => this.setState({ [e.target.name]: e.target.value.trim() });

    customValidityCheck(e) {
        var bResult = true;
        var PWPattern1 = /[0-9]/;
        var PWPattern2 = /[a-z]/;
        var PWPattern3 = /[A-Z]/;

        if (this.state.oldpassword.length < 8 || !PWPattern1.test(this.state.oldpassword) || !PWPattern2.test(this.state.oldpassword) || !PWPattern3.test(this.state.oldpassword)) {
            this.refs.oldpassword.value = "";
            this.refs.password.value = "";
            this.refs.confirmpassword.value = "";
            this.errormessages.oldpassword = "Your current password should be at least 8 characters long, include at least 1 uppercase (A-Z), 1 lowercase (a-z) letter and a digit (0-9).  It may not contain your first name, surname or email username.";
            bResult = false;
        }
        else {
            if (this.refs.password.value !== this.refs.confirmpassword.value) {
                this.refs.oldpassword.value = "";
                this.refs.password.value = "";
                this.refs.confirmpassword.value = "";
                this.errormessages.password = "Your password must match your confirmation password.";
                bResult = false;
            }
            else {
                if (this.state.password.length < 8 || !PWPattern1.test(this.state.password) || !PWPattern2.test(this.state.password) || !PWPattern3.test(this.state.password)) {
                    this.refs.oldpassword.value = "";
                    this.refs.password.value = "";
                    this.refs.confirmpassword.value = "";
                    this.errormessages.password = "Your password should be at least 8 characters long, include at least 1 uppercase (A-Z), 1 lowercase (a-z) letter and a digit (0-9).  It may not contain your first name, surname or email username.";
                    bResult = false;
                }
                else {
                    this.errormessages.password = "Please enter a valid password.";
                    this.errormessages.oldpassword = "Please enter your current password.";
                }
            }
        }

        return bResult;
    }

    handleSubmit = event => {

        const form = event.currentTarget;

        if (this.customValidityCheck(event) === false || form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        else {
            event.preventDefault();
            event.stopPropagation();

            this.setState({
                submitting: true
            }, function () {
                // Submit the request to create a new user.

                Axios.post("/resetpassword", JSON.stringify(
                    {
                        oldpassword: this.state.oldpassword,
                        password: this.state.password
                    }), {}).then(response => {
                        this.refs.oldpassword.value = "";
                        this.refs.password.value = "";
                        this.refs.confirmpassword.value = "";
                        this.setState({ UpdateConfirmed: true, submitting: false });

                        // Submit a GA event

                        ReactGA.event({
                            category: "Account",
                            action: "Password-Reset"
                        });
                    }).catch(error => {

                        console.log(error);

                        //if (typeof error.response.data === "undefined") { this.errormessages.submission = "Error"; }
                        //else {
                        //    this.errormessages.submission = error.response.data.errormessage;
                        //}
                        this.setState({ formError: true, submitting: false });
                    });
            });
        }

        this.setState({ validated: true });
    };

    async CloseModal() {
        this.setState({ UpdateConfirmed: false });
    };

    render() {
        return (
            <>
                <Modal show={this.state.UpdateConfirmed} onHide={this.CloseModal.bind(this)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Password Update Confirmed</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Thank you.  Your password has now been updated.</Modal.Body>
                    <Modal.Footer>
                        <Button variant="success" onClick={this.CloseModal.bind(this)}>
                            Close
                         </Button>
                    </Modal.Footer>
                </Modal>
                <div className="row smallfillerpanel">
                </div>
                <div className="row justify-content-center">
                    <div className="loginpanel border col-md-6 col-lg-5 col-sm-4">
                        <Form className="login" noValidate validated={this.state.validated} onSubmit={this.handleSubmit}>
                            <FormText className="box-header">
                                Password Reset
                            </FormText>
                            <FormText className="box-text">
                                Your new password should be at least 8 characters long, include at least 1 uppercase (A-Z), 1 lowercase (a-z) letter and a digit (0-9).
                            </FormText>
                            {this.state.formError && <Alert variant="danger">{this.errormessages.submission}</Alert>}

                            <FormGroup controlId="OldPasswordGroup">
                                <FormLabel>Current Password</FormLabel>
                                <InputGroup>
                                    <InputGroup.Prepend>
                                        <InputGroup.Text id="inputGroupPrepend"><FaLock className="companycolour" /></InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <FormControl name="oldpassword" ref="oldpassword" type="password" placeholder="***********" required onChange={this.UpdateState} />
                                    <Form.Control.Feedback type="invalid">{this.errormessages.oldpassword}</Form.Control.Feedback>
                                </InputGroup>
                            </FormGroup>
                            <FormGroup controlId="PasswordGroup">
                                <FormLabel>New Password</FormLabel>
                                <InputGroup>
                                    <InputGroup.Prepend>
                                        <InputGroup.Text id="inputGroupPrepend"><FaLock className="companycolour" /></InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <FormControl name="password" ref="password" type="password" placeholder="***********" required onChange={this.UpdateState} />
                                    <Form.Text className="text-muted">
                                        Your password should be at least 8 characters long, include at least 1 uppercase (A-Z), 1 lowercase (a-z) letter and a digit (0-9).  It may not contain your first name, surname or email username.
                                    </Form.Text>
                                    <Form.Control.Feedback type="invalid">{this.errormessages.password}</Form.Control.Feedback>
                                </InputGroup>
                            </FormGroup>
                            <FormGroup controlId="PasswordVerifyGroup">
                                <FormLabel>Verify your New Password</FormLabel>
                                <InputGroup>
                                    <InputGroup.Prepend>
                                        <InputGroup.Text id="inputGroupPrepend"><FaLock className="companycolour" /></InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <FormControl name="confirmpassword" ref="confirmpassword" type="password" placeholder="***********" required onChange={this.UpdateState} />
                                    <Form.Control.Feedback type="invalid">{this.errormessages.password}</Form.Control.Feedback>
                                </InputGroup>
                            </FormGroup>
                            <FormGroup controlId="ButtonGroup">
                                <FormLabel>&nbsp;</FormLabel>
                                <InputGroup>
                                    <Button id="submitbutton" variant="primary" type="submit" className="RegisterButton">{this.state.submitting && <Loader type="Watch" color="#ffffff" height={30} width={30} />}{!this.state.submitting && <span id="submitText">Reset my password</span>}</Button>
                               </InputGroup>
                            </FormGroup>
                        </Form>
                    </div>
                </div>
                <div className="row smallfillerpanel"></div>
            </>
        );
    }
}
