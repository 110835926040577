import React, { Component } from 'react';
import Axios from 'axios';

import { StateContext } from '../../context/AppContext';
import { withRouter, Link } from 'react-router-dom'

import Alert from 'react-bootstrap/Alert';

import { FaLock, FaAt } from 'react-icons/fa';

import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import FormGroup from 'react-bootstrap/FormGroup';
import InputGroup from 'react-bootstrap/InputGroup';
import FormLabel from 'react-bootstrap/FormLabel';
import FormText from 'react-bootstrap/FormText';
import FormCheck from 'react-bootstrap/FormCheck';
import Button from 'react-bootstrap/Button';

import queryString from 'query-string'


class ForgottenPassword extends Component {

    static contextType = StateContext;

    state = {
        email: '',
        validated: false,
        accepted: false
    };

    UpdateState = (e) => this.setState({ [e.target.name]: e.target.value.trim() });

    componentDidMount() {


    };

    processForgottenPassword = event => {

        const form = event.currentTarget;

        event.preventDefault();
        event.stopPropagation();

        if (form.checkValidity() === false) {
            this.setState({ validated: false });
            event.preventDefault();
            event.stopPropagation();
        }
        else {

            // Submit the request to create a new user.

            Axios.post("/forgottenpassword", JSON.stringify({email: this.state.email}), {
            }).then(response => {

                if (response.status === 200) {
                    this.setState({ accepted: true });
                }

            }).catch(error => {

                // if (typeof error.response.data === "undefined") { this.errormessages.submission = "Error"; }
                // else {
                //     this.errormessages.submission = error.response.data.errormessage;
                // }

                this.setState({ validated: true });
            });
        }
    }

    render() {
        return (
            <>
                <div className="row fillerpanel">
                </div>
                <div className="row justify-content-center">
                    <div className="loginpanel border col-md-4 col-lg-4 col-sm-4">
                        <Form className="login" validated={this.state.validated} onSubmit={this.processForgottenPassword.bind(this)}>
                            <FormText className="box-header">
                                Reset your password
                            </FormText>
                            <FormText className="box-text">
                                If you've forgotten your password and would like to reset it, please enter in your account email address and click the 'Send Reset' button to start the process.
                            </FormText>

                            {this.state.accepted &&
                                <Alert variant="success">
                                    Thank you. If the email address is linked to a 3Scribe account then you should receive an email shortly containing a link to reset your password.
                                </Alert>
                            }

                            <FormGroup controlId="EmailAddressGroup">
                                <FormLabel>Email address</FormLabel>
                                <InputGroup>
                                    <InputGroup.Prepend>
                                        <InputGroup.Text id="inputGroupPrepend"><FaAt className="companycolour" /></InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <FormControl name="email" type="email" placeholder="Enter email" autoFocus="autofocus" onChange={this.UpdateState} />
                                    <FormControl.Feedback type="invalid">Please enter a valid email address</FormControl.Feedback>
                                </InputGroup>
                            </FormGroup>

                            <FormGroup>
                                <Button type="submit" variant="primary">Send Reset</Button>
                            </FormGroup>
                        </Form>
                    </div>
                </div>
                <div className="row fillerpanel"></div>
                <div className="row fillerpanel"></div>
            </>
        );
    }
}

export default withRouter(ForgottenPassword);
