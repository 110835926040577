import React, { Component } from 'react'

import { StateContext } from '../../context/AppContext';
import { withRouter} from 'react-router-dom';

import { FaLanguage } from 'react-icons/fa';

import Alert from 'react-bootstrap/Alert';

import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import FormGroup from 'react-bootstrap/FormGroup';
import InputGroup from 'react-bootstrap/InputGroup';
import FormLabel from 'react-bootstrap/FormLabel';
import FormText from 'react-bootstrap/FormText';
import Button from 'react-bootstrap/Button';
import Loader from 'react-loader-spinner';

import Axios from 'axios'
import ReactGA from "react-ga4";

import { FormCheck } from 'react-bootstrap';

class Settings extends Component {

    static contextType = StateContext;

    globals = {};
    globalsdispatcher = null;

    async RefreshGlobals() {

        const [globals, dispatch] = this.context;
        this.globals = globals;
        this.globalsdispatcher = dispatch;
    };

    state = {
        settingspage: true,
        accountpage: false,
        defaultjoblanguage: "",
        onhold: false,
        detectlanguage: false,
        defaultretentionperiod: 525600,
        defaultretentionperiodvalue: 1,
        defaultretentionperiodrange: 4,
        UpdateConfirmed: false,
        submitting: false
    }

    errormessages = {
        defaultjoblanguage: "",
        onhold: "",
        detectlanguage: ""
    };

    jobLanguages = [
        { code: "en-US", name: "English (United States)" },
        { code: "en-GB", name: "English (Great Britain)" },
        { code: "bg-BG", name: "Bulgarian" },
        { code: "hr-HR", name: "Croatian" },
        { code: "cs-CZ", name: "Czech" },
        { code: "da-dk", name: "Danish" },
        { code: "nl-NL", name: "Dutch" },
        { code: "fi-FI", name: "Finnish" },
        { code: "fr-FR", name: "French" },
        { code: "de-DE", name: "German" },
        { code: "el-GR", name: "Greek" },
        { code: "hu-HU", name: "Hungarian" },
        { code: "it-IT", name: "Italian" },
        { code: "nb-NO", name: "Norwegian" },
        { code: "pl-PL", name: "Polish" },
        { code: "pt-PT", name: "Portugese" },
        { code: "ro-RO", name: "Romanian" },
        { code: "sk-SK", name: "Slovak" },
        { code: "sl-SI", name: "Slovenian" },
        { code: "es-US", name: "Spanish (United States)" },
        { code: "es-ES", name: "Spanish (Spain)" },
        { code: "sv-SE", name: "Swedish" },
        { code: "tr-TR", name: "Turkish" }
    ];

    async componentWillMount() {

        await this.RefreshGlobals();

        if (!this.globals.IsLoggedIn || !this.globals._authtoken) {
            this.props.history.push('/login?redirecturi=settings');
        }
    };

    componentDidMount() {
        this.LoadAccountDetails();
    };

    LoadAccountDetails() {

        Axios.get("/account", {
        }).then(response => {

            // Calculate a friendly view of the retention period

            var defaultretentionperiodvalue = 1;
            var defaultretentionperiodrange = 4;

            if (((response.data.defaultretentionperiod / 525600) % 1) === 0) {
                defaultretentionperiodvalue = response.data.defaultretentionperiod / 525600;
                defaultretentionperiodrange = 4;
            }
            else {
                if (((response.data.defaultretentionperiod / 10080) % 1) === 0) {
                    defaultretentionperiodvalue = response.data.defaultretentionperiod / 10080;
                    defaultretentionperiodrange = 3;
                }
                else {
                    if (((response.data.defaultretentionperiod / 1440) % 1) === 0) {
                        defaultretentionperiodvalue = response.data.defaultretentionperiod / 1440;
                        defaultretentionperiodrange = 2;
                    }
                    else {
                        if (((response.data.defaultretentionperiod / 60) % 1) === 0) {
                            defaultretentionperiodvalue = response.data.defaultretentionperiod / 60;
                            defaultretentionperiodrange = 1;
                        }
                    }
                }
            }

            this.setState({
                defaultjoblanguage: response.data.defaultjoblanguage,
                onhold: response.data.onhold,
                detectlanguage: response.data.detectlanguage,
                defaultretentionperiod: response.data.defaultretentionperiod,
                defaultretentionperiodrange: defaultretentionperiodrange,
                defaultretentionperiodvalue: defaultretentionperiodvalue
            });
        }).catch((error) => {

            if (typeof error.response === "undefined" || typeof error.response.data === "undefined") { this.errormessages.submission = "Error"; }
            else {
                //    this.errormessages.submission = error.response.data.errormessage;
            }
            this.setState(this.state);
        });

    };

    UpdateState = (e) => this.setState({ [e.target.name]: e.target.value.trim() });
    UpdateCheckboxState = (e) => this.setState({ [e.target.name]: e.target.checked });

    UpdateRetentionPeriod = (e) => {

        this.setState({
            [e.target.name]: e.target.value
        }, function () {

            // Recalculate the retention period

            var retentionPeriod = 525600;

            switch (Number(this.state.defaultretentionperiodrange)) {
                case 1:
                    retentionPeriod = this.state.defaultretentionperiodvalue * 60;
                    break;
                case 2:
                    retentionPeriod = this.state.defaultretentionperiodvalue * 1440;
                    break;
                case 3:
                    retentionPeriod = this.state.defaultretentionperiodvalue * 10080;
                    break;
                case 4:
                    retentionPeriod = this.state.defaultretentionperiodvalue * 525600;
                    break;
                default:
                    retentionPeriod = this.state.defaultretentionperiodvalue * 525600;
                    break;
            }

            this.setState({ defaultretentionperiod: retentionPeriod });
        });

        return null;
    }

    Validate() {

        var bResult = true;
        this.setState(this.state);
        return bResult;
    };

    customValidityCheck(e) {

        var bResult = true;
        return bResult;
    };

    handleSubmit = event => {

        const [{ IsLoggedIn }, dispatch] = this.context;
        const form = event.currentTarget;

        if (this.customValidityCheck(event) === false || form.checkValidity() === false) {
        }
        else {
            this.setState({
                submitting: true
            }, function () {
                // Submit the request to create a new user.

                Axios.put("/account", JSON.stringify(this.state), {
                }).then(response => {
                    this.setState({ UpdateConfirmed: true, submitting: false });

                    // Update the local data with the default job language 

                    dispatch({
                        parameter: '_DefaultJobLanguage',
                        newValue: this.state.defaultjoblanguage
                    });

                    // Submit a GA event

                    ReactGA.event({
                        category: "Settings",
                        action: "Update Settings"
                    });

                }).catch(error => {
                    if (typeof error.response.data === "undefined") { this.errormessages.submission = "Error"; }
                    else {
                        this.errormessages.submission = error.response.data.errormessage;
                    }
                    this.setState({ formError: true, submitting: false });
                });
            });
        }

        this.setState({ validated: true });
        event.preventDefault();
        event.stopPropagation();
    };

    async CloseModal() {
        this.setState({ UpdateConfirmed: false });
    };

    render() {
        return (
            <div class="page-component__bg_image_box bg-white-color  first_component   " id="text-07-345071">
                <div class="page-component__bg_overlay_box ">
                </div>
                <div style={{ position: "relative", zindex: 100 }}>
                    <Modal show={this.state.UpdateConfirmed} onHide={this.CloseModal.bind(this)}>
                        <Modal.Header closeButton>
                            <Modal.Title>Update Confirmed</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>Thank you.  Your account details have now been updated.</Modal.Body>
                        <Modal.Footer>
                            <Button variant="success" onClick={this.CloseModal.bind(this)}>
                                Close
                            </Button>
                        </Modal.Footer>
                    </Modal>
                    <div className="row smallfillerpanel">
                    </div>
                    <div className="row justify-content-center">
                        <div className="loginpanel border col-md-6 col-lg-5 col-sm-4">
                            <Form className="login" noValidate validated={this.state.validated} onSubmit={this.handleSubmit}>
                                <FormText className="box-header">
                                    Edit your settings
                                </FormText>
                                <FormText className="box-text">
                                    Update settings that control how we process your transcription jobs
                                </FormText>
                                {this.state.formError && <Alert variant="danger">{this.errormessages.submission}</Alert>}

                                <FormGroup controlId="DefaultJobLanguageGroup">
                                    <FormLabel>Default Job Language</FormLabel>
                                    <InputGroup>
                                        <InputGroup.Prepend>
                                            <InputGroup.Text id="inputGroupPrepend"><FaLanguage className="companycolour" /></InputGroup.Text>
                                        </InputGroup.Prepend>
                                        <Form.Control name="defaultjoblanguage" as="select" required onChange={this.UpdateState} value={this.state.defaultjoblanguage}>
                                            {this.jobLanguages.map((language) => {
                                                return (
                                                    <option key={language.code} value={language.code}>{language.name}</option>
                                                );
                                            })}
                                        </Form.Control>
                                    </InputGroup>
                                </FormGroup>

                                <FormGroup controlId="DetectLanguageGroup">
                                    <FormLabel><br />Detect Language</FormLabel>
                                    <FormCheck name="detectlanguage" checked={this.state.detectlanguage} onChange={this.UpdateCheckboxState} type="checkbox" label="Check this box if you would like 3Scribe to automatically attempt to detect the language spoken in your media file." />
                                </FormGroup>

                                <FormGroup controlId="NewsletterGroup">
                                    <FormLabel><br />On Hold</FormLabel>
                                    <FormCheck name="onhold" checked={this.state.onhold} onChange={this.UpdateCheckboxState} type="checkbox" label="Check this box if you would like your transcription jobs to be placed on hold and not immediately processed.  You are not charged for jobs that are on hold, only when they are requested to be processed." />
                                </FormGroup>

                                <FormGroup controlId="RetentionPeriodGroup">
                                    <FormLabel><br />Default Retention Period<br /><br />Select how long you would like to retain your transcription jobs on 3Scribe for.  Once the retention period for a job has been reached it will be deleted from our system along with any associated media files.</FormLabel>
                                    <InputGroup>
                                        <Form.Control name="defaultretentionperiodvalue" as="select" required onChange={this.UpdateRetentionPeriod} value={this.state.defaultretentionperiodvalue}>
                                            {Array(50).fill().map((_, idx) => { return (<option key={idx + 1} value={idx + 1}>{idx + 1}</option>); })}
                                        </Form.Control>
                                        <Form.Control name="defaultretentionperiodrange" as="select" required onChange={this.UpdateRetentionPeriod} value={this.state.defaultretentionperiodrange}>
                                            <option key={1} value={1}>Hour{(this.state.defaultretentionperiodvalue > 1 ? "s" : "")}</option>
                                            <option key={2} value={2}>Day{(this.state.defaultretentionperiodvalue > 1 ? "s" : "")}</option>
                                            <option key={3} value={3}>Week{(this.state.defaultretentionperiodvalue > 1 ? "s" : "")}</option>
                                            <option key={4} value={4}>Year{(this.state.defaultretentionperiodvalue > 1 ? "s" : "")}</option>
                                        </Form.Control>
                                    </InputGroup>
                                </FormGroup>

                                <FormGroup controlId="ClockGroup">
                                    <FormLabel>&nbsp;</FormLabel>
                                    <InputGroup>
                                        <Button id="submitbutton" variant="primary" type="submit" className="RegisterButton">{this.state.submitting && <Loader type="Watch" color="#ffffff" height={30} width={30} />}{!this.state.submitting && <span id="submitText">Update settings</span>}</Button>
                                    </InputGroup>
                                </FormGroup>
                            </Form>
                        </div>
                    </div>
                    <div className="row fillerpanel"></div>
                </div>
            </div>
        );
    }
}

export default withRouter(Settings);