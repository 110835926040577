import Axios from 'axios';
import React, { Component } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Navbar from 'react-bootstrap/Navbar';
import { withRouter } from 'react-router-dom';
import Panels from '../../components/Panels';
import { StateContext } from '../../context/AppContext';
import Login from '../../pages/account/Login';
import queryString from 'query-string'

class OAuthRegistration extends Component {

    constructor(props) {
        super(props);
    }

    static contextType = StateContext;

    state = {
        AuthorizeDisabled: false
    }

    componentDidMount() {
    }

    processAuthorization = event => {

        this.setState({ AuthorizeDisabled: true });

        var additionalData = {};

        [].forEach.call(document.querySelectorAll('[data-bs-toggle="additionalData"]'), function (item) { additionalData[item.id] = item.value; });

        const params = queryString.parse(this.props.location.search);
        Object.keys(params).forEach(function (key) { additionalData[key] = params[key]; });

        Axios.post("https://integrations.3scri.be/register", {
            service: this.props.service,
            //querystring: this.props.location.search,
            additionaldata: additionalData
        }).then(response => {

            // Redirect/react as appropiate or close the window  

            response.data.actions.map(sAction => {
                var sVerb = (sAction.indexOf(":") > -1 ? sAction.substring(0, sAction.indexOf(":")) : sAction);
                var sNoun = (sAction.indexOf(":") > -1 ? sAction.substring(sAction.indexOf(":") + 1) : "");

                switch (sVerb) {
                    case "redirect":
                        window.location.replace(sNoun);
                        break;

                    case "panel":
                        this.setState({ panel: sNoun });
                        break;

                    case "close":
                        window.open("", "_self");
                        window.close();
                        break;
                };
            });

        }).catch((error) => {
            if (error.message === "Operation canceled due to invald token.") this.props.history.push('/login');

            this.props.history.push("/integrations/error/" + this.props.service);
        });

        event.preventDefault();
        event.stopPropagation();
    }

    render() {

        const [{ IsLoggedIn }] = this.context;

        return (
            <>
                <Navbar collapseOnSelect expand="lg" variant="3scribe" className="nav-02 navbar-light">
                    <Navbar.Brand>
                        <img
                            alt="logo"
                            src={window.location.protocol + '//' + window.location.host + '/logo.svg'}
                            width="50"
                            height="50"
                            className="d-inline-block align-center"
                        />
                        &nbsp;3Scribe
                    </Navbar.Brand>
                </Navbar>

                {(
                    (!IsLoggedIn && <Login redirectoverride={"integrations/register/" + this.props.service} querystringoverride={this.props.location.search} />) ||
                    (IsLoggedIn &&
                        <Form className="authorize" onSubmit={this.processAuthorization}>
                            <Panels pagetitle="OAuth Authentication" panelslist={['OAuth/register/' + this.props.service]} />
                            <div className="row justify-content-center">
                                <Button type="submit" variant="primary" id="AuthorizeButton" disabled={this.state.AuthorizeDisabled}>{(this.state.AuthorizeDisabled ? "AUTHORIZING" : "AUTHORIZE")}</Button>
                            </div>
                        </Form>
                    )
                )}
            </>
        );
    }
}

export default withRouter(OAuthRegistration);