import React, { Component, useContext } from 'react';
import { withRouter } from 'react-router-dom';
import { StateContext } from '../context/AppContext';

import { Link } from 'react-router-dom';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';


class Navigation extends Component {

    static contextType = StateContext;

    state = {
        IsLoggedIn: false,
        UserName: "",
        Gravitar: ""
    }

    componentDidMount() {
        const [{ IsLoggedIn, _authtoken, _username, _gravitar }] = this.context;

        if (!IsLoggedIn || !_authtoken) {
            this.setState({ IsLoggedIn: false });
            this.setState({ UserName: "" });
            this.setState({ Gravitar: "" });
        }
        else {
            this.setState({ IsLoggedIn: true });
            this.setState({ UserName: _username });
            this.setState({ Gravitar: _gravitar });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const [{ IsLoggedIn, _username, _gravitar }] = this.context;

        if (prevState.IsLoggedIn !== IsLoggedIn) {
            this.setState({ IsLoggedIn: IsLoggedIn });
            this.setState({ UserName: _username });
            this.setState({ Gravitar: _gravitar });
        }
    }    
    
    render() {
        return (
            <Navbar collapseOnSelect expand="lg" variant="3scribe" className="nav-02 navbar-light">
                <Navbar.Brand as={Link} to="/">
                    <img
                        alt="logo"
                        src={window.location.protocol + '//' + window.location.host + '/logo.svg'}
                        width="50"
                        height="50"
                        className="d-inline-block align-center"
                    />
                    &nbsp;3Scribe
                    </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav" className="">
                    <Nav className="mr-auto">
                        {this.state.IsLoggedIn && <Nav.Link as={Link} to="/jobs">Transcription Jobs</Nav.Link>}
                        <a className="nav-link" href="https://developers.3scri.be">API</a>
                        <a className="nav-link" href="https://helpcentre.3scri.be">Help</a>                        
                    </Nav>
                    <Nav className="ml-auto">
                        {!this.state.IsLoggedIn && <Nav.Link eventKey="1" as={Link} to="/register">Register</Nav.Link>}
                        {!this.state.IsLoggedIn && <Nav.Link eventKey="2" as={Link} to="/login">Login</Nav.Link>}
                        {this.state.IsLoggedIn &&
                            <NavDropdown title={this.state.UserName} id="basic-nav-dropdown" className="">
                                <Nav.Link eventKey="3" as={Link} to="/account">Account</Nav.Link>
                                <Nav.Link eventKey="4" as={Link} to="/payments">Payments</Nav.Link>
                            <Nav.Link eventKey="5" as={Link} to="/apikey">API Key</Nav.Link>
                            <Nav.Link eventKey="6" as={Link} to="/settings">Settings</Nav.Link>
                            { /* <Nav.Link eventKey="6" as={Link} to="/integrations/yourintegrations">Integrations</Nav.Link> */}
                                <NavDropdown.Divider />
                                <Nav.Link eventKey="7" as={Link} to="/logout">Logout</Nav.Link>
                            </NavDropdown>
                        }
                    </Nav>
                </Navbar.Collapse>
                </Navbar>
        );
    }
}

export default withRouter(Navigation);
