import ReactGA from 'react-ga4'
import './3scribe.scss';
import './KnowledgeBase.scss';

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { StateProvider } from './context/AppContext';
import { Elements, StripeProvider } from 'react-stripe-elements';

ReactGA.initialize('G-E6T9RR572V')

ReactDOM.render(
    <StateProvider>
        <App />
    </StateProvider>
    , document.getElementById('root'));
