import React, { Component } from 'react';
import Axios from 'axios'
import { StateContext } from '../../context/AppContext';
import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import SplitButton from 'react-bootstrap/SplitButton';
import Modal from 'react-bootstrap/Modal';
//import { Link } from 'react-router-dom';

import { secondsToHoursMinutes, formatDate, formatTime } from '../../globals/text';

class JobDetails extends Component {

    static contextType = StateContext;

    globals = {};
    globalsdispatcher = null;

    RefreshGlobals() {

        const [globals, dispatch] = this.context;
        this.globals = globals;
        this.globalsdispatcher = dispatch;
    };

    state = {
        JobID: this.props.match.params.jobid,
        JobDetails: {
            jobid: "",
            jobname: "",
            transcription: {
                text: ""
            },
            jobstatus: "",
            creationdate: "",
            duration: 0
        },
        ConfirmDelete: false,
        DeletionTarget: null,
        DownloadPreference: "txt"
    }

    errormessages = {}

    constructor(props) {
        super(props);
    }

    componentDidMount() {

        this.RefreshGlobals();

        if (!this.globals.IsLoggedIn || !this.globals._authtoken) {
            this.props.history.push('/login?redirecturi=jobdetails/' + this.props.match.params.jobid);
        }
        else {
            this.LoadJobDetails();
        }
    }

    UpdateState = (e) => this.setState({ [e.target.name]: e.target.value.trim() });

    LoadJobDetails() {

        // Set the deletion target in case it is required

        this.setState({ DeletionTarget: this.props.match.params.jobid });

        // Load the job details

        Axios.get("/jobs/" + this.props.match.params.jobid, {
        }).then(response => {
            this.setState({ JobDetails: response.data });
        }).catch((error) => {

            if (typeof error.response === "undefined" || typeof error.response.data === "undefined") { this.errormessages.submission = "Error"; }
            else {
                //    this.errormessages.submission = error.response.data.errormessage;
            }
            this.setState(this.state);
        });
    }

    DeleteJob = () => {

        this.setState({
            ConfirmDelete: true
        });
    }

    CancelDeletion() {
        this.setState({ ConfirmDelete: false });
    }

    ConfirmDeletion() {

        this.setState({
            ConfirmDelete: false
        }, function () {
            Axios.delete("/jobs/" + this.state.DeletionTarget, {
            }).then(response => {
                this.props.history.push('/jobs');
            }).catch((error) => {
                if (typeof error.response === "undefined" || typeof error.response.data === "undefined") { this.errormessages.submission = "Error"; }
                else {
                    //    this.errormessages.submission = error.response.data.errormessage;
                }
                this.setState(this.state);
            });
        });
    }

    DownloadJob = (sFormat) => {

        Axios.get("/jobs/" + this.state.JobDetails.jobid, {
            responseType: "blob",
            params: {
                mode: "file",
                format: sFormat
            }
        }).then(response => {

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', this.state.JobDetails.jobname + ".txt"); // + sFormat);
            document.body.appendChild(link);
            link.click();

        }).catch((error) => {

            this.setState(this.state);
        });
    }

    render() {
        return (
            <>
                <Modal show={this.state.ConfirmDelete} onHide={this.CancelDeletion.bind(this)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Confirm Job Deletion</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Please confirm that you wish to delete job: <span className="makebold">{this.state.DeletionTarget && this.state.JobDetails.jobname}</span>.  This action cannot be reversed.</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.CancelDeletion.bind(this)}>
                            Cancel
                         </Button>
                        <Button variant="danger" onClick={this.ConfirmDeletion.bind(this)}>
                            Delete
                        </Button>
                    </Modal.Footer>
                </Modal>
                <div className="row justify-content-md-center listminheight">
                    <div className="col-md-10">
                        <div className="row">
                            <div className="col-md-5 largetext makebold">
                                {this.state.JobDetails.jobname}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-1 text makebold">Uploaded:</div>
                            <div className="col-md-3 text makebold">{formatDate(this.state.JobDetails.creationdate)}</div>
                            <div className="col-md-1 text makebold">Duration:&nbsp;&nbsp;</div>
                            <div className="col-md-3 text makebold">{secondsToHoursMinutes(this.state.JobDetails.duration)}</div>
                            <div className="addflex col-md-4 text makebold justify-content-md-end">
                                <SplitButton id="dropdown-basic-button" title={'Download job: ' + this.state.JobDetails.jobname + " as a " + this.state.DownloadPreference + " file."} onClick={() => { this.DownloadJob(this.state.DownloadPreference) }} title="Download Transcript">
                                    <Dropdown.Item eventKey="1" onClick={() => { this.DownloadJob("txt") }} value="txt" active> as a text file</Dropdown.Item>
                                    <Dropdown.Item eventKey="2" onClick={() => { this.DownloadJob("json") }} value="json">as JSON data</Dropdown.Item>
                                    <Dropdown.Item eventKey="3" onClick={() => { this.DownloadJob("nvivo") }} value="nvivo">as NVIVO subtitles</Dropdown.Item>
                                </SplitButton>
                                &nbsp;&nbsp;
                                <Button id="delete" variant="danger" onClick={() => { this.DeleteJob() }}>Delete</Button>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12 text">
                                {this.state.JobDetails.transcription.text}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="smallfillerpanel"></div>
            </>
        )
    }
}

export default JobDetails;