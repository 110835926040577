import React, { Component } from 'react'

import { StateContext } from '../context/AppContext';
import { withRouter, Link } from 'react-router-dom';

import { FaLock, FaAt } from 'react-icons/fa';

import Alert from 'react-bootstrap/Alert';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import FormGroup from 'react-bootstrap/FormGroup';
import InputGroup from 'react-bootstrap/InputGroup';
import FormLabel from 'react-bootstrap/FormLabel';
import FormText from 'react-bootstrap/FormText';
import Button from 'react-bootstrap/Button';
import Loader from 'react-loader-spinner';
import { FormCheck } from 'react-bootstrap';

import Axios from 'axios'
import Recaptcha from 'react-google-invisible-recaptcha';

import { Helmet } from "react-helmet";

export default class SupportTicket extends Component {
    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onResolved = this.onResolved.bind(this);
        this.onCaptchaError = this.onCaptchaError.bind(this);
    }

    static contextType = StateContext;

    state = {
        subject: "",
        description: "",
        validated: false,
        submitting: false,
        submitted: false,
        recaptcha: ""
    }

    errormessages = {
        submission: "",
        subject: "",
        description: ""
    }

    UpdateState = (e) => this.setState({ [e.target.name]: e.target.value.trim() });

    onCaptchaError() {

        document.getElementById("submitbutton").disabled = false;
        this.errormessages.submission = "Error on reCaptcha";

        this.setState({
            formError: true
        });
    }

    onResolved() {
        this.setState({
            submitting: true,
            recaptcha: this.recaptcha.getResponse()
        }, function () {
            // Submit the request to create a new user.

            document.getElementById("submitbutton").disabled = true;

            Axios.post("/tickets", JSON.stringify(this.state), {
            }).then(response => {
                this.errormessages.submission = "Your ticket has been submitted.  Support will contact you within 24 hours.";
                document.getElementById("submitbutton").disabled = false;                
                this.setState({ formError: false, submitting: false, submitted: true });

            }).catch(error => {

                console.log(error);

                document.getElementById("submitbutton").disabled = false;
                this.errormessages.submission = "Unfortunately an error occurred and we were unable to log your ticket.  Please send an email to support@3scri.be describing your issue and we will log it for you.";
                this.setState({ formError: true, submitting: false, submitted: false });
            });
        });

        document.getElementById("submitbutton").disabled = false;
        this.setState({ validated: false });
    };

    handleSubmit(event) {
        try {
            const form = event.currentTarget;
            if (form.checkValidity() === false) {

                this.setState({ validated: true });
                event.preventDefault();
                event.stopPropagation();
            }
            else {
                this.recaptcha.execute();
                event.preventDefault();
                event.stopPropagation();
            }
        }
        catch
        {
            event.preventDefault();
            event.stopPropagation();
        }
    };

    render() {

        const [{ recaptcha_key }] = this.context;

        return (
            <>
                <div className="row justify-content-center">
                    <div className="loginpanel border col-md-8 col-lg-7 col-sm-6">
                        <Form className="login" noValidate validated={this.state.validated} onSubmit={this.handleSubmit}>
                            <FormText className="box-header">
                                <span className="boxHeader companycolor">Submit a Ticket</span>
                            </FormText>
                            <FormText className="box-text">
                                We're sorry that you've encountered an issue whilst using 3Scribe but please describe what the problem is in the box below and click the Submit button.  
                                &nbsp;&nbsp;Our support staff will get back to you with an answer to your query within 24 hours via the email address currently registered on your account.  
                                &nbsp;&nbsp;In the meantime why not browse through our <Link to="/knowledgebase">Knowledge Base</Link> where you'll find the answers to the most commonly asked questions. 
                            </FormText>
                            {this.state.formError && <Alert variant="danger">{this.errormessages.submission}</Alert>}
                            {this.state.submitted && <Alert variant="success">{this.errormessages.submission}</Alert>}

                            <FormGroup controlId="SubjectGroup">
                                <FormLabel>Subject</FormLabel>
                                <FormControl name="subject" placeholder="Briefly describe the issue you're having." autoFocus="autofocus" onChange={this.UpdateState} required ref="subject" />
                                <Form.Control.Feedback type="invalid">Briefly describe the issue you're having</Form.Control.Feedback>
                            </FormGroup>

                            <FormGroup controlId="DescriptionGroup">
                                <FormLabel>Description</FormLabel>
                                <FormControl name="description" as="textarea" rows={8} placeholder="Please describe the issue you're having with 3Scribe." autoFocus="autofocus" onChange={this.UpdateState} required ref="description" />
                                <Form.Control.Feedback type="invalid">Please describe the issue you're having with 3Scribe</Form.Control.Feedback>
                            </FormGroup>

                            <Recaptcha
                                ref={ref => this.recaptcha = ref}
                                sitekey={recaptcha_key}
                                onResolved={this.onResolved}
                                onError={this.onCaptchaError} />

                            <Button id="submitbutton" variant="primary" type="submit" className="RegisterButton">{this.state.submitting && <Loader type="Watch" color="#ffffff" height={30} width={30} />}{!this.state.submitting && <span id="submitText">Submit Ticket</span>}</Button>
                        </Form>
                    </div>
                </div>
                <div className="row fillerpanel"></div>
            </>
        );
    }
}