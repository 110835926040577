import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { StateContext } from '../context/AppContext';
import Axios from 'axios';
import Pagination from 'react-bootstrap/Pagination';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';

import { FaLanguage } from 'react-icons/fa';

import InputGroup from 'react-bootstrap/InputGroup';
import FormLabel from 'react-bootstrap/FormLabel';
import FormGroup from 'react-bootstrap/FormGroup';
import Form from 'react-bootstrap/Form';


import { FaSort, FaSortUp, FaSortDown, FaTrashAlt, FaDownload, FaRegPlayCircle } from 'react-icons/fa';
import Loader from 'react-loader-spinner';
import { secondsToHoursMinutes, formatDate, formatTime } from '../globals/text';

import { toast } from 'react-toastify';

class JobsList extends Component {

    constructor(props) {
        super(props)
        this.pageChanged = this.pageChanged.bind(this);
        this.perPageChanged = this.perPageChanged.bind(this);
    }

    static contextType = StateContext;

    jobLanguages = [
        { code: "en-US", name: "English (United States)" },
        { code: "en-GB", name: "English (Great Britain)" },
        { code: "bg-BG", name: "Bulgarian" },
        { code: "hr-HR", name: "Croatian" },
        { code: "cs-CZ", name: "Czech" },
        { code: "da-dk", name: "Danish" },
        { code: "nl-NL", name: "Dutch" },
        { code: "fi-FI", name: "Finnish" },
        { code: "fr-FR", name: "French" },
        { code: "de-DE", name: "German" },
        { code: "el-GR", name: "Greek" },
        { code: "hu-HU", name: "Hungarian" },
        { code: "it-IT", name: "Italian" },
        { code: "nb-NO", name: "Norwegian" },
        { code: "pl-PL", name: "Polish" },
        { code: "pt-PT", name: "Portugese" },
        { code: "ro-RO", name: "Romanian" },
        { code: "sk-SK", name: "Slovak" },
        { code: "sl-SI", name: "Slovenian" },
        { code: "es-US", name: "Spanish (United States)" },
        { code: "es-ES", name: "Spanish (Spain)" },
        { code: "sv-SE", name: "Swedish" },
        { code: "tr-TR", name: "Turkish" }
    ];

    state = {
        Page: 1,
        PerPage: 10,
        Order: 1,
        Direction: 1,
        Jobs: [],
        PaginationItems: [],
        PerPageText: "10 items per page",
        IsLoading: true,
        IsInitialLoad: true,
        ConfirmDelete: false,
        DeletionTarget: null,
        ConfirmRestart: false,
        RestartTarget: {
            jobid: '',
            jobname: ''
        },
        DownloadPreference: "txt",
        LastJobID: "",
        LastServerMessage: "",
        joblanguage: "en-US"
    };

    errormessages = {}

    componentDidMount() {

        const [{ IsLoggedIn, _authtoken, _JobsList }] = this.context;

        if (!IsLoggedIn || !_authtoken) {
            this.props.history.push('/login?redirecturi=jobs');
        }

        if (_JobsList) {
            this.setState({
                PerPage: _JobsList.PerPage,
                Order: _JobsList.Order,
                Direction: _JobsList.Direction,
                PerPageText: _JobsList.PerPage + " items per page",
                LastJobID: this.props.lastjob
            },
                this.LoadJobs
            );
        }
        else {
            this.setState({
                LastJobID: this.props.lastjob
            },
                this.LoadJobs
            );
        }

        this.setState({ joblanguage: this.props.defaultlanguage });
    }

    componentDidUpdate() {
        if (this.props.lastjob !== this.state.LastJobID) {
            this.setState({
                LastJobID: this.props.lastjob
            },
                this.LoadJobs
            );
        }

        if (this.props.servermessage && this.props.servermessage !== this.state.LastServerMessage) {

            var loMessage = null;

            this.setState({
                LastServerMessage: this.props.servermessage
            });

            // Parse the message and pop a toast message if needed.

            try {
                loMessage = JSON.parse(this.props.servermessage);
            }
            catch (e) {
                loMessage = this.props.servermessage;
            }

            if (loMessage.messagetype === "jobstatusupdate") {

                var lsMessage = "";

                switch (loMessage.jobstatus) {
                    case "Processing":
                        lsMessage = loMessage.jobname + " is now processing.";
                        break;
                    case "Completed":
                        lsMessage = loMessage.jobname + " has now completed.";
                        break;
                }

                // Update the record

                let oRecord = this.state.Jobs.find(x => x.jobid === loMessage.jobid);

                if (!oRecord) {
                    // Record not found in current list.  Run a refresh to see if it is now in paging database

                    this.LoadJobs();
                    let oRecord = this.state.Jobs.find(x => x.jobid === loMessage.jobid);
                }

                if (oRecord) {
                    // Check to avoid downgrading

                    var bGood = false;

                    if (oRecord.jobstatus === "Loading" && (loMessage.jobstatus === "Analyzing" || loMessage.jobstatus === "Processing" || loMessage.jobstatus === "Completed" || loMessage.jobstatus === "On Hold" || loMessage.jobstatus === "Failed" || loMessage.jobstatus === "Insufficient_Funds")) bGood = true;
                    if (oRecord.jobstatus === "Analyzing" && (loMessage.jobstatus === "Processing" || loMessage.jobstatus === "Completed" || loMessage.jobstatus === "On Hold" || loMessage.jobstatus === "Failed" || loMessage.jobstatus === "Insufficient_Funds")) bGood = true;
                    if (oRecord.jobstatus === "Processing" && (loMessage.jobstatus === "Completed" || loMessage.jobstatus === "On Hold" || loMessage.jobstatus === "Failed" || loMessage.jobstatus === "Insufficient_Funds")) bGood = true;

                    if (bGood === true && lsMessage !== "") {
                        toast(lsMessage, {
                            bodyClassName: "toast-message",
                        });

                        oRecord.jobstatus = loMessage.jobstatus;
                        oRecord.duration = loMessage.duration;
                    }
                    else {
                        if (oRecord.jobstatus === "Processing" && loMessage.jobstatus === "Processing") {
                            oRecord.jobstatus = loMessage.jobstatus;
                            oRecord.duration = loMessage.duration;
                        }
                    }
                }
            }
        }
    }

    UpdateState = (e) => this.setState({ [e.target.name]: e.target.value.trim() });

    LoadJobs() {
        this.setState({
            IsLoading: true
        }, function () {
            Axios.get("/jobs", {
                params: {
                    page: this.state.Page,
                    order: this.state.Order,
                    direction: this.state.Direction,
                    perpage: this.state.PerPage
                }
            }).then(response => {
                this.setState({
                    IsInitialLoad: false,
                    Jobs: response.data.jobs
                });

                // Set the pagination items

                this.generatePaging(response.data.perpage, response.data.page, response.data.totaljobs);

            }).catch((error) => {
                if (error.message === "Operation canceled due to invald token.") this.props.history.push('/login');
                this.setState(this.state);
            });
        });
    }

    DeleteJob = (job) => {
        this.setState({
            ConfirmDelete: true,
            DeletionTarget: job
        });
    }

    RestartJob = (job) => {
        this.setState({
            ConfirmRestart: true,
            RestartTarget: job
        });
    }

    ChangeOrder(iOrder) {

        const [{ _JobsList }, dispatch] = this.context;

        let iDirection = this.state.Direction;

        if (this.state.Order === iOrder) {
            if (this.state.Direction === 1) {
                iDirection = 2;
            }
            else {
                iDirection = 1;
            }
        }

        this.setState({
            Order: iOrder,
            Direction: iDirection
        }, function () {
            dispatch({
                parameter: '_JobsList',
                newValue: {
                    ..._JobsList,
                    Order: this.state.Order,
                    Direction: this.state.Direction
                }
            });

            this.LoadJobs();
        }
        );
    }

    generatePaging(PerPage, Page, TotalItems) {

        let PagesShown = 10;
        let TotalPages = Math.ceil(TotalItems / PerPage);
        let Start = Math.max(1, Page - (PagesShown / 2));
        let End = Math.min(TotalPages, Math.max(Math.max(Page, PagesShown), Math.min(TotalPages, Page + (PagesShown / 2))));
        let PaginationItems = [];

        if (TotalPages > 1) {
            if (Start > 1) {
                PaginationItems.push(<Pagination.Prev key={Start - 1} />);
                PaginationItems.push(<Pagination.Item key={1}>{1}</Pagination.Item>);
                PaginationItems.push(<Pagination.Ellipsis key={-1} />);
            }

            for (let loop = Start; loop <= End; loop++) {
                PaginationItems.push(
                    <Pagination.Item key={loop} active={loop === Page}>
                        {loop}
                    </Pagination.Item>,
                );
            }

            if (End < TotalPages) {
                PaginationItems.push(<Pagination.Ellipsis key={-2} />);
                PaginationItems.push(<Pagination.Item key={TotalPages}>{TotalPages}</Pagination.Item>);
                PaginationItems.push(<Pagination.Next key={End + 1} />);
            }
        }
        else {
            PaginationItems.push(
                <Pagination.Item key="1" active>1</Pagination.Item>
            );
        }

        this.setState({ PaginationItems: PaginationItems });
    }

    pageChanged(e) {

        const [{ _JobsList }, dispatch] = this.context;

        this.setState({
            Page: e.target.text
        }, function () {
            dispatch({
                parameter: '_JobsList',
                newValue: {
                    ..._JobsList,
                    Order: this.state.Order,
                    Direction: this.state.Direction,
                    PerPage: this.state.PerPage
                }
            });

            this.LoadJobs();
        });
    }

    perPageChanged(newPerPage) {

        const [{ _JobsList }, dispatch] = this.context;

        this.setState({
            PerPage: newPerPage,
            PerPageText: newPerPage + " items per page"
        }, function () {
            dispatch({
                parameter: '_JobsList',
                newValue: {
                    ..._JobsList,
                    Order: this.state.Order,
                    Direction: this.state.Direction,
                    PerPage: this.state.PerPage,
                    Page: this.state.Page
                }
            });

            this.LoadJobs();
        });
    }

    CancelDeletion() {
        this.setState({ ConfirmDelete: false });
    }

    CancelRestart() {
        this.setState({ ConfirmRestart: false });
    }

    ConfirmDeletion() {

        this.setState({ ConfirmDelete: false });

        // Mark the record as being deleted

        let oRecord = this.state.Jobs.find(x => x.jobid === this.state.DeletionTarget.jobid);
        oRecord.jobstatus = "Deleting";

        // Send the delete request

        Axios.delete("/jobs/" + this.state.DeletionTarget.jobid, {
        }).then(response => {
            toast(response.data.jobname + " has been deleted", {
                bodyClassName: "toast-message",
            });

            this.setState({
                Jobs: this.state.Jobs.filter(x => x.jobid !== this.state.DeletionTarget.jobid),
                DeletionTarget: null
            });

            //this.LoadJobs();
        }).catch((error) => {
            if (typeof error.response === "undefined" || typeof error.response.data === "undefined") { this.errormessages.submission = "Error"; }
            else {
                //    this.errormessages.submission = error.response.data.errormessage;
            }
            this.setState(this.state);
        });

    }

    DownloadJob = (job) => {

        Axios.get("/jobs/" + job.jobid, {
            responseType: "blob",
            params: {
                mode: "file",
                format: this.state.DownloadPreference
            }
        }).then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', job.jobname + "." + this.state.DownloadPreference);
            document.body.appendChild(link);
            link.click();

        }).catch((error) => {
            this.setState(this.state);
        });
    }

    ConfirmRestart() {

        // Hide the modal

        this.setState({ ConfirmRestart: false });

        // Send the restart request to the server

        Axios.put("/jobs/" + this.state.RestartTarget.jobid, {
            "language": this.state.joblanguage
        })
            .then(response => {
                toast(response.data.jobname + " is restarting.", {
                    bodyClassName: "toast-message",
                });
            }).catch((error) => {
                this.setState(this.state);
            });
    };

    render() {
        return (
            <>
                <Modal show={this.state.ConfirmDelete} onHide={this.CancelDeletion.bind(this)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Confirm Job Deletion</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Please confirm that you wish to delete job: <span className="makebold">{this.state.DeletionTarget && this.state.DeletionTarget.jobname}</span>.  This action cannot be reversed.</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.CancelDeletion.bind(this)}>
                            Cancel
                         </Button>
                        <Button variant="danger" onClick={this.ConfirmDeletion.bind(this)}>
                            Delete
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Modal show={this.state.ConfirmRestart} onHide={this.CancelRestart.bind(this)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Confirm Transcription Restart</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        Please confirm that you wish to restart the processing of the following file:
                        <br /><br />
                        <span key={this.state.RestartTarget.jobid}><b>{this.state.RestartTarget.jobname}<br /></b></span>
                        <br />
                        <FormGroup controlId="JobLanguageGroup">
                            <FormLabel>in this language:</FormLabel>
                            <InputGroup>
                                <InputGroup.Prepend>
                                    <InputGroup.Text id="inputGroupPrepend"><FaLanguage className="companycolour" /></InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control name="joblanguage" as="select" onChange={this.UpdateState} value={this.state.joblanguage}>
                                    {this.jobLanguages.map((language) => {
                                        return (
                                            <option key={language.code} value={language.code}>{language.name}</option>
                                        );
                                    })}
                                </Form.Control>
                            </InputGroup>
                        </FormGroup>
                        <br /><br />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.CancelRestart.bind(this)}>
                            Cancel
                         </Button>
                        <Button className="ConfirmButton" onClick={this.ConfirmRestart.bind(this)}>
                            Confirm
                        </Button>
                    </Modal.Footer>
                </Modal>
                <div className="row">
                    <div className="addflex col-3 offset-6 align-content-centre justify-content-end"><Pagination onClick={this.pageChanged}>{this.state.PaginationItems}</Pagination></div>
                    <div className="addflex col-2 justify-content-center">
                        <DropdownButton id="dropdown-basic-button" title={this.state.PerPageText} onSelect={this.perPageChanged}>
                            <Dropdown.Item eventKey="10">10 items</Dropdown.Item>
                            <Dropdown.Item eventKey="25">25 items</Dropdown.Item>
                            <Dropdown.Item eventKey="50">50 items</Dropdown.Item>
                            <Dropdown.Item eventKey="100">100 items</Dropdown.Item>
                        </DropdownButton>
                    </div>
                </div>
                <div className="row justify-content-md-center listminheight">
                    <div className="col-md-10">
                        <div className="row">
                            <div className="col-md-4 col-lg-5 tableheader columnsort">
                                <div className="row">
                                    <div className="col-1 align-content-centre">
                                    </div>
                                    <div onClick={() => { this.ChangeOrder(1) }}>Job Name&nbsp;{(this.state.Order === 1 ? (this.state.Direction === 1 ? <FaSortUp /> : <FaSortDown />) : <FaSort />)}</div>
                                </div>
                            </div>
                            <div className="col-md-2 tableheader columnsort" onClick={() => { this.ChangeOrder(2) }}>Length&nbsp;{(this.state.Order === 2 ? (this.state.Direction === 1 ? <FaSortUp /> : <FaSortDown />) : <FaSort />)}</div>
                            <div className="col-md-2 tableheader columnsort" onClick={() => { this.ChangeOrder(3) }}>Status&nbsp;{(this.state.Order === 3 ? (this.state.Direction === 1 ? <FaSortUp /> : <FaSortDown />) : <FaSort />)}</div>
                            <div className="col-md-3 col-lg-2 tableheader columnsort" onClick={() => { this.ChangeOrder(4) }}>Date Requested&nbsp;{(this.state.Order === 4 ? (this.state.Direction === 1 ? <FaSortUp /> : <FaSortDown />) : <FaSort />)}</div>
                            <div className="col-md-1 tableheader"></div>
                        </div>
                        {(
                            this.state.IsInitialLoad === true ?
                                <div className="row listminheight">
                                    <div className="addflex col-1 offset-5 align-self-center">
                                        <Loader
                                            type="Watch"
                                            color="#0094ec"
                                            height={50}
                                            width={50}
                                        />
                                    </div>
                                </div>
                                :
                                this.state.Jobs.map((job) => {
                                    return (
                                        <div className="row jobrow" key={job.jobid}>
                                            <div className="col-md-4 col-lg-5 tableitem jobLink centertext">
                                                <div className="row">
                                                    <div className="col-1 align-content-centre">
                                                        {job.jobsource !== "Direct" && <img src={"../../assets/logos/" + job.jobsource.toLowerCase() + "-1616.png"} title={job.jobsource} />}
                                                    </div>
                                                    <div>
                                                        {job.jobstatus === "Completed" &&
                                                            <Link to={{
                                                                pathname: '/jobdetails/' + job.jobid
                                                            }}>{job.jobname}</Link>}
                                                        {job.jobstatus !== "Completed" && job.jobname}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-2 tableitem centertext">{secondsToHoursMinutes(job.duration)}</div>
                                            <div className="col-md-2 tableitem centertext">{job.jobstatus.replace("_", " ")}</div>
                                            <div className="col-md-3 col-lg-2 tableitem centertext">{formatDate(job.creationdate)}</div>
                                            <div className="col-md-1">
                                                <div className="row">
                                                    <div className="col-1 align-content-centre">
                                                        {((job.jobstatus.replace("_", " ") === "On Hold" || job.jobstatus.replace("_", " ") === "Insufficient Funds") && job.jobid !== this.state.RestartTarget.jobid) && <FaRegPlayCircle className="restart" title={'Restart job: ' + job.jobname} onClick={() => { this.RestartJob(job) }} />}
                                                    </div>
                                                    <div className="col-1 align-content-centre">
                                                        {job.jobstatus === "Completed" && <FaDownload className="download" title={'Download job: ' + job.jobname + " as a " + this.state.DownloadPreference + " file."} onClick={() => { this.DownloadJob(job) }} />}
                                                    </div>
                                                    <div className="col-1 align-content-centre">
                                                        {(job.jobstatus !== "Deleting" && job.jobstatus !== "ProXcessing" && job.jobstatus !== "LoXading") && <FaTrashAlt className="trash" title={'Delete job: ' + job.jobname} onClick={() => { this.DeleteJob(job) }} />}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })
                        )}
                    </div>
                </div>
                <div className="smallfillerpanel"></div>
            </>
        );
    }
}

export default withRouter(JobsList);