export const secondsToHoursMinutes = (seconds, zeroText) => {

    try {
        var sZeroText = (zeroText ? zeroText : "");

        if (seconds <= 0) {
            return sZeroText;
        }

        seconds = Number(seconds);
        var h = Math.floor(seconds / 3600);
        var m = Math.floor(seconds % 3600 / 60);
        var s = Math.floor(seconds % 3600 % 60);

        var hDisplay = h > 0 ? h + (h === 1 ? " hour" : " hours") : "";
        var mDisplay = m > 0 ? m + (m === 1 ? " minute" : " minutes") : "";
        var sDisplay = s > 0 ? s + (s === 1 ? " second" : " seconds") : "";

        var sOutput = hDisplay;
        sOutput += (hDisplay !== "" ? (mDisplay !== "" && sDisplay !== "" ? ", " : ((mDisplay !== "" && sDisplay === "") || (mDisplay === "" && sDisplay !== "") ? " and " : "")) : "");
        sOutput += (mDisplay !== "" ? (sDisplay !== "" ? mDisplay + " and " : mDisplay) : "");
        sOutput += (sDisplay !== "" ? sDisplay : "");
        sOutput = (sOutput === "" ? "" : sOutput);
        return sOutput;
    }
    catch {
        return "";
    }
};

export const minutesToHoursMinutes = minutes => {

    if (isNaN(Number(minutes))) {
        return "";
    }

    minutes = Number(minutes);
    var seconds = minutes * 60;
    var h = Math.floor(seconds / 3600);
    var m = Math.floor(seconds % 3600 / 60);
    var s = Math.floor(seconds % 3600 % 60);

    var hDisplay = h > 0 ? h + (h === 1 ? " hour" : " hours") : "";
    var mDisplay = m > 0 ? m + (m === 1 ? " minute" : " minutes") : "";
    var sDisplay = s > 0 ? s + (s === 1 ? " second" : " seconds") : "";

    var sOutput = hDisplay;
    sOutput += (hDisplay !== "" ? (mDisplay !== "" && sDisplay !== "" ? ", " : ((mDisplay !== "" && sDisplay === "") || (mDisplay === "" && sDisplay !== "") ? " and " : "")) : "");
    sOutput += (mDisplay !== "" ? (sDisplay !== "" ? mDisplay + " and " : mDisplay) : "");
    sOutput += (sDisplay !== "" ? sDisplay : "");
    sOutput = (sOutput === "" ? "" : sOutput);

    return sOutput;
};

export const formatDate = (d) => {

    try {
        var temp = new Date(d);
        if (isNaN(temp.getDate())) { return ""; }
        const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        return (temp.getDate() < 10 ? "0" : "") + temp.getDate() + " " + monthNames[temp.getMonth()] + " " + temp.getFullYear() + "  -  " + (temp.getHours() < 10 ? "0" : "") + temp.getHours() + ":" + (temp.getMinutes() < 10 ? "0" : "") + temp.getMinutes();
    }
    catch {
        return "";
    }
}

export const formatTime = (d) => {

    try {
        var temp = new Date(d);
        if (isNaN(temp.getDate())) { return ""; }
        return temp.getHours() + ":" + temp.getMinutes();
    }
    catch {
        return "";
    }
}

