import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom'

import { Helmet } from "react-helmet";

class ZapierEmbed extends Component {

    render() {
        return (
            <div className="centered">
                <Helmet>
                    <script type="module" src="https://cdn.zapier.com/packages/partner-sdk/v0/zapier-elements/zapier-elements.esm.js"></script>
                    <link rel="stylesheet" href="https://cdn.zapier.com/packages/partner-sdk/v0/zapier-elements/zapier-elements.css" />
                </Helmet>
                <div className="width80">
                    <h2>Easily connect your Zapier and 3Scribe accounts using the tool below.</h2>
                    <div className="smallfillerpanel"></div>
                    <zapier-full-experience
                        client-id="G7dONUGkkN4d8fRosI4rQcghP8QVtxaLFPVKQFhA"
                        theme="light"
                        app-search-bar-display="show"
                        zap-limit={10}
                        zap-call-to-action-display="show"
                        zap-create-from-scratch-display="hide"
                    />
                    <div className="fillerpanel"></div>
                </div>
            </div>
        )
    }
}


export default withRouter(ZapierEmbed);