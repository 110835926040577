import React, { Component } from 'react';
import Axios from 'axios';
import { StateContext } from '../../context/AppContext';

const auth0 = require("auth0-js");

class Logout extends Component {

    static contextType = StateContext;

    componentDidMount() {

        const [{ auth0_clientid, auth0_domain }, dispatch] = this.context;

        // Initializing our Auth0 SDK

        var webAuth = new auth0.WebAuth({
            domain: auth0_domain,
            clientID: auth0_clientid,
            responseType: "id_token",
            redirectUri: window.location.origin.toString() + "/"
        });

        // Clear the Local Storage

        dispatch({
            parameter: '_authtoken',
            newValue: ""
        });

        dispatch({
            parameter: 'IsLoggedIn',
            newValue: false
        });

        dispatch({
            parameter: '_username',
            newValue: ""
        });

        dispatch({
            parameter: '_gravitar',
            newValue: ""
        });

        // Remove the token from Axios

        Axios.defaults.headers.common['Authorization'] = "";

        // Remove the JWT from the central storage

        webAuth.logout({
            returnTo: window.location.origin.toString() + "/",
            clientID: auth0_clientid         
        });
    }

    ProcessError = (authResult) => {

        console.log(authResult);
    }

    state = {
    }

    render() {
        return (<></>);
    }
}

export default Logout;