import React, { Component } from 'react';
import { withRouter, location, Link } from 'react-router-dom'
import Axios from 'axios';
import { StateContext } from '../context/AppContext';

import { Helmet } from "react-helmet";

class Panels extends Component {

    static contextType = StateContext;

    state = {
        language: 'en',
        panelHTML: ''
    }

    panelshtml = [];
    pagetitle = "";

    componentDidMount() {

        const [{ _SiteLanguage }] = this.context;

        this.setState({ language: _SiteLanguage });
        this.panelshtml.length = this.props.panelslist.length;

        if (this.props.pagetitle) {
            this.pagetitle = this.props.pagetitle;
        }

        let iCounter = 0;

        const axiosInstance = Axios.create({
            baseURL: window.location.protocol + "//" + window.location.hostname + ":" + window.location.port
        });

        this.props.panelslist.forEach((panelname, iCounter = iCounter + 1) => {
            axiosInstance.get("panels/" + _SiteLanguage + "/" + panelname + ".html", {
                headers: {
                    'Content-Type': "text"
                }
            }).then(response => {
                let temphtml = this.state.panelshtml;
                this.panelshtml[iCounter] = response.data;
                this.setState({ panelHTML: this.panelshtml.join('') }, function () {
                    
                    if (this.props.postCall) {
                        this.props.postCall(window, document);
                    }
                });
            }).catch((error) => {
                //console.log(error);
            });
        });
    }

    render() {
        return (
            <>
                {this.pagetitle !== "" &&  
                    <Helmet>
                    <title>{this.pagetitle} | 3Scribe</title>
                    </Helmet>             
                }
                <div dangerouslySetInnerHTML={{ __html: this.state.panelHTML }} />
            </>
        );
    }
}

export default withRouter(Panels);
